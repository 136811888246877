import React from 'react';
import { hydrate, render } from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';

/* CSS */
import './index.css';
import './App.css';

/* 페이지 */
import App from './App';

const cookies = new Cookies();

axios.defaults.headers.common['X-AUTH-TOKEN'] = cookies.get('accessToken')

axios.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
});

const commonTimeFormatter = target => {
  const keys = Object.keys(target);

  keys.forEach(key => {
    if (typeof target[key] === 'string' && (key === 'lastLogin' || key.indexOf('Date') > -1)) {
      target[key] = target[key]?.replace('T', ' ');
    }
  });
};

axios.interceptors.response.use(response => {
  if (response?.data?.data) {
    const bodyData = response?.data?.data;

    if (Array.isArray(bodyData)) {
      bodyData.forEach(commonTimeFormatter);
    } else if (Array.isArray(bodyData?.content)) {
      bodyData.content.forEach(commonTimeFormatter);
    } else if (typeof bodyData === 'object') {
      commonTimeFormatter(bodyData);
    }
  }

  return response;
}, error => {
  return Promise.reject(error);
});

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
      <BrowserRouter>
        <HelmetProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </HelmetProvider>
      </BrowserRouter>,
      rootElement
  );
} else {
  render(
      <BrowserRouter>
        <HelmetProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </HelmetProvider>
      </BrowserRouter>,
      rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

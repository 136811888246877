import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const InfoLanding = topPad => {
  const isMobile = useMediaQuery({ query: '(max-width: 1240px)' });

  const onClickIos = () => {
    window.open(
      'https://apple.co/3Dss4Fk',
    );
  };
  const onClickAndroid = () => {
    window.open(
      'https://bit.ly/44DFxWR',
    );
  };

  return (
    <>
      <LandingWrap>
        {!isMobile && (
          <Visualection>
            <VisualInner>
              <VisualTit>
                <h4>
                  대한민국 1등 뷰티샵
                  <br />
                  예약 어플 뷰티북
                </h4>
                <span>
                  최신 뷰티정보부터 뷰티샵 초특하 할인혜택까지
                  <br />
                  네일, 속눈썹, 브로우, 왁싱, 태닝 등 다양한 카테고리로 구성된
                  <br />
                  국내 최대할인 뷰티샵 예약 플랫폼, 뷰티북을 만나보세요!
                </span>
              </VisualTit>
            </VisualInner>
          </Visualection>
        )}
        {isMobile && (
          <Visualection>
            <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/m_info_main.png" alt="모바일 비쥬얼" />
            <VisualInner>
              <VisualTit>
                <h4>
                  대한민국 1등 뷰티샵
                  <br />
                  예약 어플 뷰티북
                </h4>
                <span>
                  최신 뷰티정보부터 뷰티샵 초특하 할인혜택까지
                  <br />
                  네일, 속눈썹, 브로우, 왁싱, 태닝 등 다양한 카테고리로 구성된
                  <br />
                  국내 최대할인 뷰티샵 예약 플랫폼, 뷰티북을 만나보세요!
                </span>
              </VisualTit>
            </VisualInner>
          </Visualection>
        )}
        {!isMobile && (
          <>
            <LandSection>
              <SecInner>
                <SecTit>
                  <span>Main Facility</span>
                  <h4>뷰티북의 대표 기능</h4>
                </SecTit>

                <DescList>
                  <ul>
                    <li>
                      <ImgBox>
                        <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/mf_01.svg" alt="할인혜택" />
                      </ImgBox>
                      <ImgDesc>
                        <strong>할인혜택</strong>
                        <span>
                          할인가로 만나는
                          <br />
                          뷰티샵 시술 혜택!
                        </span>
                      </ImgDesc>
                    </li>
                    <li>
                      <ImgBox>
                        <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/mf_02.svg" alt="뷰티포인트" />
                      </ImgBox>
                      <ImgDesc>
                        <strong>뷰티포인트</strong>
                        <span>
                          결제 시 최대 3%
                          <br />
                          포인트 적립
                        </span>
                      </ImgDesc>
                    </li>
                    <li>
                      <ImgBox>
                        <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/mf_03.svg" alt="뷰티 트렌드" />
                      </ImgBox>
                      <ImgDesc>
                        <strong>뷰티 트렌드</strong>
                        <span>
                          스타는 물론 셀럽들의
                          <br />
                          최신 뷰티정보!
                        </span>
                      </ImgDesc>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <ImgBox>
                        <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/mf_04.svg" alt="주변 검색" />
                      </ImgBox>
                      <ImgDesc>
                        <strong>주변 검색</strong>
                        <span>
                          위치 기반 지도 검색으로
                          <br />내 주변 샵 한눈에!
                        </span>
                      </ImgDesc>
                    </li>
                    <li>
                      <ImgBox>
                        <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/mf_05.svg" alt="리얼리뷰" />
                      </ImgBox>
                      <ImgDesc>
                        <strong>리얼리뷰</strong>
                        <span>
                          리얼리뷰를 카테고리 별로
                          <br />
                          한눈에 확인가능!
                        </span>
                      </ImgDesc>
                    </li>
                  </ul>
                </DescList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <SecTit>
                  <span>Special Benefit</span>
                  <h4>뷰티북만의 특별한 혜택</h4>
                </SecTit>

                <BenefitList topPad={topPad}>
                  <BfDesc>
                    <i />
                    <strong>매월 쏟아지는 할인혜택</strong>
                    <span>
                      쿠폰팩 할인, 첫시술 할인, 친구초대 이벤트 등<br />
                      할인가로 만나는 뷰티샵 시술 혜택!
                    </span>
                  </BfDesc>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/benfit_01.svg" alt="할인혜택" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6">
              <SecInner>
                <BenefitList>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/benfit_02.svg" alt="할인혜택" />
                  </BfImg>
                  <BfDescR>
                    <i />
                    <strong>예뻐질때마다 쌓이는 뷰티포인트</strong>
                    <span>결제시 최대 3% 포인트 적립</span>
                  </BfDescR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>뷰티 트렌드를 한 눈에!</strong>
                    <span>
                      스타는 물론 셀럽들의 최신 뷰티정보의 A부터 Z까지!
                      <br />
                      뷰티북으로 공부하면서 더 예뻐질텐데?
                    </span>
                  </BfDesc>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/benfit_03.svg" alt="할인혜택" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6" topPad={topPad}>
              <SecInner>
                <BenefitList>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/benfit_04.svg" alt="할인혜택" />
                  </BfImg>
                  <BfDescR>
                    <i />
                    <strong>내주변 뷰티샵 간편하게 검색</strong>
                    <span>
                      위치기반 지도검색으로
                      <br />
                      카테고리별 샵정보를 확인하고
                      <br />
                      바로예약, 결제까지!
                    </span>
                  </BfDescR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>사용 유저들의 리얼리뷰</strong>
                    <span>
                      실제 뷰티샵 방문 고객이 작성한 리얼리뷰를
                      <br />
                      카테고리로 모아볼 수 있어요!
                    </span>
                  </BfDesc>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/benfit_05.svg" alt="할인혜택" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
          </>
        )}
        {isMobile && (
          <>
            <LandSection topPad={topPad}>
              <SecInner>
                <SecTit>
                  <span>Special Benefit</span>
                  <h4>뷰티북만의 특별한 혜택</h4>
                </SecTit>

                <BenefitList topPad={topPad}>
                  <BfDesc>
                    <i />
                    <strong>매월 쏟아지는 할인혜택</strong>
                    <span>
                      쿠폰팩 할인, 첫시술 할인, 친구초대 이벤트 등<br />
                      할인가로 만나는 뷰티샵 시술 혜택!
                    </span>
                  </BfDesc>
                  <BfImgR>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/m_benefit_01m.svg" alt="할인혜택" />
                  </BfImgR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6">
              <SecInner>
                <BenefitList>
                  <BfDescR>
                    <i />
                    <strong>예뻐질때마다 쌓이는 뷰티포인트</strong>
                    <span>결제시 최대 3% 포인트 적립</span>
                  </BfDescR>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/m_benefit_02m.svg" alt="뷰티포인트" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>뷰티 트렌드를 한 눈에!</strong>
                    <span>
                      스타는 물론 셀럽들의 최신 뷰티정보의 A부터 Z까지!
                      <br />
                      뷰티북으로 공부하면서 더 예뻐질텐데?
                    </span>
                  </BfDesc>
                  <BfImgR>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/m_benefit_03m.svg" alt="뷰티 트렌드" />
                  </BfImgR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6">
              <SecInner>
                <BenefitList>
                  <BfDescR>
                    <i />
                    <strong>내주변 뷰티샵 간편하게 검색</strong>
                    <span>
                      위치기반 지도검색으로 카테고리별
                      <br />
                      샵정보를 확인하고 바로예약, 결제까지!
                    </span>
                  </BfDescR>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/m_benefit_04m.svg" alt="주변 검색" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>사용 유저들의 리얼리뷰</strong>
                    <span>
                      실제 뷰티샵 방문 고객이 작성한 리얼리뷰를
                      <br />
                      카테고리로 모아볼 수 있어요!
                    </span>
                  </BfDesc>
                  <BfImgR>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/m_benefit_05m.svg" alt="리얼리뷰" />
                  </BfImgR>
                </BenefitList>
              </SecInner>
            </LandSection>
          </>
        )}
        <Footer>
          <ButtonBox>
            <button onClick={onClickIos}>
              <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/share_as.svg" alt="애플 스토어" />
            </button>
            <button onClick={onClickAndroid}>
              <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/share_ps.svg" alt="구글 스토어" />
            </button>
          </ButtonBox>
        </Footer>
      </LandingWrap>
    </>
  );
};

// 스타일드 컴포넌트
const LandingWrap = styled.div`
  position: relative;

  & * {
    font-family: 'Noto Sans KR';
  }
`;

const Visualection = styled.section`
  position: relative;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/info_main.png') 50% 50% no-repeat;
  background-size: cover;
  height: 900px;

  @media screen and (max-width: 1240px) {
    background: transparent;
    height: 140%;
    & img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    & img {
      object-fit: cover;
    }
  }
`;

const VisualInner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1240px) {
    position: absolute;
    top: 15%;
    height: auto;
    max-width: 1240px;
    margin: 0;
    align-items: flex-start;
    padding: 0 4.82%;
  }
`;

const VisualTit = styled.div`
  padding: 0 36px;

  & h4 {
    font-weight: 700;
    font-size: 70px;
    line-height: 86px;
    color: #ffffff;
  }

  & span {
    margin-top: 25px;
    display: block;
    font-weight: 300;
    font-size: 30px;
    line-height: 42px;
    color: #fafafa;
  }

  @media screen and (max-width: 1240px) {
    padding: 0;

    & h4 {
      font-size: 35px;
      line-height: 42px;
    }

    & span {
      margin-top: 15px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  @media screen and (max-width: 480px) {
    & h4 {
      font-size: 24px;
      line-height: 28px;
    }

    & span {
      margin-top: 10px;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const LandSection = styled.section`
  padding: ${props => (props.topPad ? '0' : '65px 0')};
  position: relative;
  background: ${props => props.color || '#ffffff'};

  @media screen and (max-width: 1240px) {
    padding: ${props => (props.topPad ? '70px 0 25px' : '25px 0')};
  }

  @media screen and (max-width: 480px) {
    padding: ${props => (props.topPad ? '40px 0 25px' : '25px 0')};
  }
`;

const SecInner = styled.div`
  max-width: 1140px;
  margin: 0 auto;

  @media screen and (max-width: 1240px) {
    max-width: 1240px;
    margin: 0;
    padding: 0 36px;
  }
`;

const SecTit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px 0;
  text-align: center;

  & h4 {
    font-weight: 700;
    font-size: 44px;
    color: #000000;
  }

  & span {
    font-weight: 500;
    font-size: 26px;
    color: #e03835;
  }

  @media screen and (max-width: 1240px) {
    gap: 10px 0;

    & h4 {
      font-size: 24px;
    }

    & span {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1240px) {
    & h4 {
      font-size: 18px;
    }

    & span {
      font-size: 12px;
    }
  }
`;

const DescList = styled.div`
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 75px 0;

  & ul {
    display: flex;
    align-items: center;
    gap: 0 120px;
  }
`;

const ImgBox = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
`;

const ImgDesc = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  text-align: center;

  & strong {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
  }

  & span {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }
`;

const BenefitList = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: ${props => (props.topPad ? '65px' : '0')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1240px) {
    padding-top: ${props => (props.topPad ? '30px' : '0')};
    flex-direction: column;
    align-items: normal;
    justify-content: normal;
    gap: 40px 0;
  }
`;

const BfDesc = styled.div`
  & i {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #e03835;
  }

  & strong {
    margin-top: 10px;
    display: block;
    font-weight: 700;
    font-size: 48px;
    color: #000000;
  }

  & span {
    margin-top: 20px;
    display: block;
    font-weight: 400;
    font-size: 30px;
    line-height: 42px;
    color: #000000;
  }

  @media screen and (max-width: 1240px) {
    & i {
      width: 10px;
      height: 10px;
    }

    & strong {
      font-size: 20px;
    }

    & span {
      margin-top: 15px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    & i {
      width: 7px;
      height: 7px;
    }

    & strong {
      font-size: 16px;
    }

    & span {
      margin-top: 10px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

const BfDescR = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  & i {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #e03835;
  }

  & strong {
    margin-top: 10px;
    display: block;
    font-weight: 700;
    font-size: 48px;
    color: #000000;
  }

  & span {
    margin-top: 20px;
    display: block;
    font-weight: 400;
    font-size: 30px;
    line-height: 42px;
    color: #000000;
  }

  @media screen and (max-width: 1240px) {
    & i {
      width: 10px;
      height: 10px;
    }

    & strong {
      font-size: 20px;
    }

    & span {
      margin-top: 15px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    & i {
      width: 7px;
      height: 7px;
    }

    & strong {
      font-size: 16px;
    }

    & span {
      margin-top: 10px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

const BfImg = styled.div`
  text-align: left;

  & img {
    max-width: 100%;
  }
`;

const BfImgR = styled.div`
  text-align: right;

  & img {
    max-width: 100%;
  }
`;

const Footer = styled.div`
  padding: 70px 0;
  background: #e03835;

  @media screen and (max-width: 1240px) {
    padding: 25px 0;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 36px;

  @media screen and (max-width: 1240px) {
    & button {
      width: 125px;
      & img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 360px) {
    gap: 0 18px;
    & button {
      width: 75px;
      & img {
        width: 100%;
      }
    }
  }
`;

export default InfoLanding;

import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const AdminInfoLanding = topPad => {
  const isMobile = useMediaQuery({ query: '(max-width: 1240px)' });

  const onAdminPage = () => {
    window.open(
      'https://manager.mendlemendle.com/',
    );
  };

  return (
    <>
      <LandingWrap>
        {!isMobile && (
          <Visualection>
            <VisualInner>
              <VisualTit>
                <h4>
                  대한민국 최대할인
                  <br />
                  뷰티샵 플랫폼 뷰티북
                </h4>
                <span>
                  국내 최대할인 뷰티샵 예약 플랫폼, 뷰티북으로
                  <br />
                  신규회원 늘리고 단골고객 확보하세요!
                </span>
              </VisualTit>
            </VisualInner>
          </Visualection>
        )}
        {isMobile && (
          <Visualection>
            <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/m_info_main.png" alt="모바일 비쥬얼" />
            <VisualInner>
              <VisualTit>
                <h4>
                  대한민국 최대할인
                  <br />
                  뷰티샵 플랫폼 뷰티북
                </h4>
                <span>
                  국내 최대할인 뷰티샵 예약 플랫폼, 뷰티북으로
                  <br />
                  신규회원 늘리고 단골고객 확보하세요!
                </span>
              </VisualTit>
            </VisualInner>
          </Visualection>
        )}
        {!isMobile && (
          <>
            <LandSection topPad={topPad}>
              <SecInner>
                <SecTit>
                  <span>Special Benefit</span>
                  <h4>뷰티북만의 특별한 혜택</h4>
                </SecTit>

                <BenefitList topPad={topPad}>
                  <BfDesc>
                    <i />
                    <strong>입점비 ZERO</strong>
                    <span>입점해주시는 것도 영광인데 어떻게 받아요</span>
                  </BfDesc>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_01.svg" alt="할인혜택" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6">
              <SecInner>
                <BenefitList>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_02.svg" alt="할인혜택" />
                  </BfImg>
                  <BfDescR>
                    <i />
                    <strong>판매수수료 ZERO</strong>
                    <span>점주님을, 앱 사용유저를, 그리고 우리 모두를 위해</span>
                  </BfDescR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>디자인비 ZERO</strong>
                    <span>할인행사 배너 및 POP 무료 제공</span>
                  </BfDesc>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_03.svg" alt="할인혜택" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6">
              <SecInner>
                <BenefitList>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_04.svg" alt="할인혜택" />
                  </BfImg>
                  <BfDescR>
                    <i />
                    <strong>국내 최대 할인혜택</strong>
                    <span>점주님 부담없는 풍성한 고객 할인혜택 제공</span>
                  </BfDescR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>무상교육서비스 제공</strong>
                    <span>샵 운영에 필요한 세미나/웨비나 강좌 무료</span>
                  </BfDesc>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_05.svg" alt="할인혜택" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6" topPad={topPad}>
              <SecInner>
                <BenefitList>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_06.svg" alt="할인혜택" />
                  </BfImg>
                  <BfDescR>
                    <i />
                    <strong>유료구좌 광고 마케팅 지원</strong>
                    <span>광고비용 및 활동비 무상 지원 (자격조건 획득시)</span>
                  </BfDescR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>전국 뷰티샵 커뮤니티 운영</strong>
                    <span>뷰티샵 점주님들의 대나무숲 운영</span>
                  </BfDesc>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_07.svg" alt="할인혜택" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
          </>
        )}
        {isMobile && (
          <>
            <LandSection topPad={topPad}>
              <SecInner>
                <SecTit>
                  <span>Special Benefit</span>
                  <h4>뷰티북만의 특별한 혜택</h4>
                </SecTit>

                <BenefitList topPad={topPad}>
                  <BfDesc>
                    <i />
                    <strong>매월 쏟아지는 할인혜택</strong>
                    <span>입점해주시는 것도 영광인데 어떻게 받아요</span>
                  </BfDesc>
                  <BfImgR>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_m_01.svg" alt="입점비 Zero" />
                  </BfImgR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6">
              <SecInner>
                <BenefitList>
                  <BfDescR>
                    <i />
                    <strong>판매수수료 Zero</strong>
                    <span>점주님을, 앱 사용유저를, 그리고 우리 모두 위해</span>
                  </BfDescR>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_m_02.svg" alt="판매수수료 Zero" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>디자인비 Zero</strong>
                    <span>할인행사 배너 및 POP 무료 제공</span>
                  </BfDesc>
                  <BfImgR>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_m_03.svg" alt="디자인비 Zero" />
                  </BfImgR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6">
              <SecInner>
                <BenefitList>
                  <BfDescR>
                    <i />
                    <strong>국내 최대 할인혜택</strong>
                    <span>점주님 부담없는 풍성한 고객 할인혜택 제공</span>
                  </BfDescR>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_m_04.svg" alt="국내 최대 할인혜택" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>무상교육서비스 제공</strong>
                    <span>샵 운영에 필요한 세미나/웨비나 강좌 무료</span>
                  </BfDesc>
                  <BfImgR>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_m_05.svg" alt="무상교육서비스 제공" />
                  </BfImgR>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection color="#f6f6f6" topPad={topPad}>
              <SecInner>
                <BenefitList>
                  <BfDescR>
                    <i />
                    <strong>유료구좌 광고 마케팅 지원</strong>
                    <span>광고비용 및 활동비 무상 지원 (자격조건 획득시)</span>
                  </BfDescR>
                  <BfImg>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_m_06.svg" alt="유료구좌 광고 마케팅 지원" />
                  </BfImg>
                </BenefitList>
              </SecInner>
            </LandSection>
            <LandSection>
              <SecInner>
                <BenefitList>
                  <BfDesc>
                    <i />
                    <strong>전국 뷰티샵 커뮤니티 운영</strong>
                    <span>뷰티샵 점주님들의 대나무숲 운영</span>
                  </BfDesc>
                  <BfImgR>
                    <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/a_benfit_m_07.svg" alt="전국 뷰티샵 커뮤니티 운영" />
                  </BfImgR>
                </BenefitList>
              </SecInner>
            </LandSection>
          </>
        )}
        <Footer>
          <ButtonBox>
            <Link to={'/entered'}>
              <span>입점 상담 바로가기</span>
            </Link>
            <Link onClick={onAdminPage}>
              <span>뷰티북 어드민 둘러보기</span>
            </Link>
          </ButtonBox>
        </Footer>
      </LandingWrap>
    </>
  );
};

// 스타일드 컴포넌트
const LandingWrap = styled.div`
  position: relative;

  & * {
    font-family: 'Noto Sans KR';
  }
`;

const Visualection = styled.section`
  position: relative;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/info_main.png') 50% 50% no-repeat;
  background-size: cover;
  height: 900px;

  @media screen and (max-width: 1240px) {
    background: transparent;
    height: 140%;
    & img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    & img {
      object-fit: cover;
    }
  }
`;

const VisualInner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1240px) {
    position: absolute;
    top: 15%;
    height: auto;
    max-width: 1240px;
    margin: 0;
    align-items: flex-start;
    padding: 0 4.82%;
  }
`;

const VisualTit = styled.div`
  padding: 0 36px;

  & h4 {
    font-weight: 700;
    font-size: 70px;
    line-height: 86px;
    color: #ffffff;
  }

  & span {
    margin-top: 25px;
    display: block;
    font-weight: 300;
    font-size: 30px;
    line-height: 42px;
    color: #fafafa;
  }

  @media screen and (max-width: 1240px) {
    padding: 0;

    & h4 {
      font-size: 35px;
      line-height: 42px;
    }

    & span {
      margin-top: 15px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  @media screen and (max-width: 480px) {
    & h4 {
      font-size: 24px;
      line-height: 28px;
    }

    & span {
      margin-top: 10px;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const LandSection = styled.section`
  padding: ${props => (props.topPad ? '65px 0 0' : '65px 0')};
  position: relative;
  background: ${props => props.color || '#ffffff'};

  @media screen and (max-width: 1240px) {
    padding: ${props => (props.topPad ? '25px 0 0' : '25px 0')};
  }
`;

const SecInner = styled.div`
  max-width: 1140px;
  margin: 0 auto;

  @media screen and (max-width: 1240px) {
    max-width: 1240px;
    margin: 0;
    padding: 0 36px;
  }
`;

const SecTit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px 0;
  text-align: center;

  & h4 {
    font-weight: 700;
    font-size: 44px;
    color: #000000;
  }

  & span {
    font-weight: 500;
    font-size: 26px;
    color: #e03835;
  }

  @media screen and (max-width: 1240px) {
    gap: 10px 0;

    & h4 {
      font-size: 24px;
    }

    & span {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1240px) {
    & h4 {
      font-size: 18px;
    }

    & span {
      font-size: 12px;
    }
  }
`;

const BenefitList = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: ${props => (props.topPad ? '65px' : '0')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1240px) {
    padding-top: ${props => (props.topPad ? '30px' : '0')};
    flex-direction: column;
    align-items: normal;
    justify-content: normal;
    gap: 40px 0;
  }
`;

const BfDesc = styled.div`
  & i {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #e03835;
  }

  & strong {
    margin-top: 10px;
    display: block;
    font-weight: 700;
    font-size: 48px;
    color: #000000;
  }

  & span {
    margin-top: 35px;
    display: block;
    font-weight: 400;
    font-size: 30px;
    line-height: 42px;
    color: #000000;
  }

  @media screen and (max-width: 1240px) {
    & i {
      width: 10px;
      height: 10px;
    }

    & strong {
      font-size: 20px;
    }

    & span {
      margin-top: 15px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    & i {
      width: 7px;
      height: 7px;
    }

    & strong {
      font-size: 16px;
    }

    & span {
      margin-top: 10px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

const BfDescR = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  & i {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #e03835;
  }

  & strong {
    margin-top: 10px;
    display: block;
    font-weight: 700;
    font-size: 48px;
    color: #000000;
  }

  & span {
    margin-top: 20px;
    display: block;
    font-weight: 400;
    font-size: 30px;
    line-height: 42px;
    color: #000000;
  }

  @media screen and (max-width: 1240px) {
    & i {
      width: 10px;
      height: 10px;
    }

    & strong {
      font-size: 20px;
    }

    & span {
      margin-top: 15px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    & i {
      width: 7px;
      height: 7px;
    }

    & strong {
      font-size: 16px;
    }

    & span {
      margin-top: 10px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

const BfImg = styled.div`
  text-align: left;

  & img {
    max-width: 100%;
  }
`;

const BfImgR = styled.div`
  text-align: right;

  & img {
    max-width: 100%;
  }
`;

const Footer = styled.div`
  padding: 70px 0;
  background: #3f3f3f;

  @media screen and (max-width: 1240px) {
    padding: 25px 0;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 36px;

  & span {
    display: block;
    width: 342px;
    height: 70px;
    line-height: 70px;
    background: #e03835;
    border-radius: 10px;
    font-weight: 700;
    font-size: 26px;
    color: #ffffff;
    text-align: center;
  }

  @media screen and (max-width: 1240px) {
    & span {
      width: 180px;
      height: 45px;
      line-height: 45px;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 480px) {
    gap: 18px 0;
    flex-direction: column;

    & a {
      display: block;
      width: calc(100% - 72px);
    }

    & span {
      width: 100%;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
    }
  }
`;

export default AdminInfoLanding;

import React from 'react';
import { Link } from 'react-router-dom';

/* CSS */
import main from 'components/bb/common/css/main.module.css';
import shop from 'components/bb/common/css/shop.module.css';

/* 공통 JS */
import * as Common from 'components/common/Common';

/* 컴포넌트 */
import SquareItem from 'components/bb/components/shop/squareItem';

/* 슬라이드 - 스와이퍼 */
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';

const RecommendShop = props => {
  return (
    <>
      <div className={`${main.slideSec}`}>
        <div className={main.secHead}>
          <h4 className={main.secTit}>뷰티북 추천 샵</h4>
          {!Common.emptyValueValidation(props?.item) && (
            <Link to={'/shop?open=recommend'}>
              더보기
              <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/sec_arrow_ic.svg" alt="링크" />
            </Link>
          )}
        </div>
        {Common.emptyValueValidation(props?.item) && (
          <div className={shop.empty}>
            <p>추천 샵이 없어요.</p>
          </div>
        )}
        {!Common.emptyValueValidation(props?.item) && (
          <div className={shop.innerItem}>
            <Swiper spaceBetween={14} slidesPerView={1.8} freeMode={true} modules={[FreeMode]}>
              {props?.item?.map((item, index) => (
                <SwiperSlide key={index}>
                  <SquareItem item={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </>
  );
};

export default RecommendShop;

import axios from 'axios';
import Cookies from 'universal-cookie';
import * as native from 'components/common/native';
import * as server from 'components/common/server';

const cookies = new Cookies();

export const expireDate = new Date();

expireDate.setTime(expireDate.getTime() + 100 * 24 * 60 * 60 * 1000);

export const userIdValidation = value => {
  const val = value || '';

  return val === '' ? val : val.replace(/[^a-zA-Z0-9]/g, '');
};

export const passwordValidation = value => {
  const regex = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+.,]).{8,20}$/;

  return regex.test(value);
};

export const passwordCompare = (password, passwordChk) => {
  return password === passwordChk;
};

export const birthdayValidation = value => {
  const regex = /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/;

  return regex.test(value);
};

export const checkBirthdayOverFourTeen = value => {
  // 'YYMMDD' 형식의 문자열을 Date 객체로 변환
  const year = parseInt(value.substring(0, 2), 10);
  const month = parseInt(value.substring(2, 4), 10) - 1; // 월은 0부터 시작하므로 1을 빼줌
  const day = parseInt(value.substring(4, 6), 10);

  // 현재 날짜 가져오기
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString().substring(2, 4);
  const targetYear = (year <= 99 && year > currentYear) ? 1900 : 2000;
  // 생년월일로 Date 객체 생성
  const birthDate = new Date(targetYear + year, month, day);

  // 만 나이 계산
  let age = currentDate.getFullYear() - birthDate.getFullYear() - 1;

  // 생일이 지났는지 확인
  if (
    currentDate.getMonth() > birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() >= birthDate.getDate())
  ) {
    age++;
  }

  return age >= 14;
}

export const validateNumber = value => {
  const val = value || '';

  return val === '' ? val : val.replace(/[^0-9]/g, '');
};

export const phoneValidation = value => {
  const regPhone = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;

  return regPhone.test(value);
};

export const pointNumber = value => {
  let val = value || '';

  val = val === '' ? val : val.replace(/[^0-9]/g, '').replace(/(^0+)/, "");

  return val;
};

export const emptyValueValidation = value => {
  return value == null || value == undefined || value == 'null' || value == '' || value == ' ' || value.toString().trim() == '';
};

export const randomDigitCharactersSpecialCharactersLength = length => {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";

  for(let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export const numberWithCommas = num => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const emailValidation = value => {
  const regex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  return regex.test(value);
};

export const emailFrontValidation = value => {
  const val = value || '';

  return val === '' ? val : val.replace(/[^a-zA-Z0-9]/g, '');
};

export const emailBackValidation = value => {
  const val = value || '';

  return val === '' ? val : val.replace(/[^a-zA-Z.]/g, '');
};

export const phoneNumber = value => {
  const val = value || '';

  return val === '' ? val : val.replaceAll('-', '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

export const paymentType = value => {
  const val = value || '';

  if (val === '') {
    return val;
  } else {
    switch (val) {
      case 'CARD':
        return '신용카드';
      case 'SHOP':
        return '현장결제';
      case 'KAKAO':
        return '카카오페이';
      case 'NPAY':
        return '네이버페이';
      case 'SAMSUNGPAY':
        return '삼성페이';
      case 'APPLEPAY':
        return '애플페이';
      default:
        return '페이코';
    }
  }
};

export const getCookie = name => {
  const obj = `${name}=`;
  const cookieArray = document.cookie.split(';');

  for (let i = 0; i < cookieArray.length; i += 1) {
    let cookie = cookieArray[i];

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(obj) !== -1) {
      return cookie.substring(obj.length, cookie.length);
    }
  }

  return '';
};

export const getPaymentOrderName = product => {
  if (1 < product.length) {
    return `${product[0]['productName']} 외 ${product.length - 1}개`;
  }

  return product[0]['productName'];
};

Date.prototype.days = function () {
  let year = this.getFullYear();
  let month = this.getMonth() + 1 < 10 ? '0' + (this.getMonth() + 1) : this.getMonth();
  let day = this.getDate() < 10 ? '0' + this.getDate() : this.getDate();

  return year + '년 ' + month + '월 ' + day + '일 ';
};
Date.prototype.dateSlash = function () {
  let month = this.getMonth() + 1 < 10 ? '0' + (this.getMonth() + 1) : this.getMonth();
  let day = this.getDate() < 10 ? '0' + this.getDate() : this.getDate();

  return month + '/' + day;
};
Date.prototype.amPm = function () {
  return this.getHours() < 12 ? '오전 ' : '오후 ';
};
Date.prototype.hours = function () {
  let hours = this.getHours() > 12 ? this.getHours() - 12 : this.getHours();
  let mm = this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();

  return (hours < 10 ? '0' + hours : hours) + '시 ' + mm + '분';
};
Date.prototype.hoursColon = function () {
  let hours = this.getHours() > 12 ? this.getHours() - 12 : this.getHours();
  let mm = this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes();

  return (hours < 10 ? '0' + hours : hours) + ':' + mm;
};

export const pointState = value => {
  const val = value || '';

  if (val === '') {
    return val;
  } else {
    switch (val) {
      case 'P':
        return '포인트 적립';
      case 'U':
        return '결제 시 사용';
      case 'S':
        return '결제 적립 예정';
      case 'C':
        return '결제 시 사용 취소';
      default:
        return '포인트 적립 예정 취소';
    }
  }
};

export const pointNumClass = (state) => {
  const val = state || '';

  if (val === '') {
    return val;
  } else {
    switch (val) {
      case 'P':
        return 'plus';
      case 'U':
        return '';
      case 'S':
        return 'plus';
      case 'C':
        return 'plus';
      default:
        return 'through';
    }
  }
};

export const pointPrice = (state, price) => {
  const val = state || '';

  if (val === '') {
    return val;
  } else {
    switch (val) {
      case 'P':
        return '+' + price.toLocaleString() + '원';
      case 'U':
        return '-' + price.toLocaleString() + '원';
      case 'S':
        return '+' + price.toLocaleString() + '원';
      case 'C':
        return '+' + price.toLocaleString() + '원';
      default:
        return price.toLocaleString() + '원';
    }
  }
};

export const randomImage = () => {
  const images = [
    'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/tibu_1.svg',
    'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/tibu_2.svg',
    'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/tibu_3.svg',
    'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/tibu_4.svg',
    'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/titi_1.svg',
    'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/titi_2.svg',
    'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/titi_3.svg',
    'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/titi_4.svg',
  ];

  return images[Math.floor(Math.random() * images.length)];
};

// 필수 선언 구문 (START)
const deviceType = (function() {
  let device = '';
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    device= 'ios';
  } else if(userAgent.match( /Android/i )) {
    device= 'android';
  } else {
    device= 'unknown';
  }

  return device;
})();

export const isAndroid = (function() {
  return deviceType === "android" && typeof window.AOS !== "undefined";
})();

export const isIOS = (function() {
  return deviceType === "ios" && typeof window.IOS !== "undefined";
})();

export const loginCookie = res => {
  const { jwt, refreshToken, userLoginStatus } = res['resultObject'];

  axios.defaults.headers.common['X-AUTH-TOKEN'] = jwt;

  cookies.set('refreshToken', refreshToken, { expires: expireDate });
  cookies.set('accessToken', jwt, { expires: expireDate });
  cookies.set('loginStatus', userLoginStatus, { expires: expireDate });

  if (isIOS || isAndroid) {
    let param = {
      'refreshToken': refreshToken,
      'accessToken': jwt,
      'loginStatus': userLoginStatus,
    }

    native.appRequest.setJson(param);
  }
};

const historyBackEvent = () => {
  switch (window.location.pathname) {
    case '/booking/check' :
      document.location.href = '/mypage/booking?tap=' + window.location.search.split('tap=')[1];

      break;
    case '/mypage/booking' :
      document.location.href = '/mypage';

      break;
    case '/entered' :
      document.location.href = '/home';

      break;
    default :
      window.history.back();

      break;
  }
};

window.historyBackEvent = historyBackEvent;

export const moveUrl = url => {
  if (isIOS || isAndroid) {
    native.appRequest.openUrlEx(url);
  } else {
    window.open(url);
  }
};

window.moveUrl = moveUrl;

export const subStringHref = text => {
  const sHrefPosition = [];
  const eHrefPosition = [];
  const sSrcPosition = [];
  const eSrcPosition = [];

  let pos = text.indexOf('href="');

  while (pos > - 1) {
    sHrefPosition.push(pos);

    pos = text.indexOf('href="', pos + 1);
  }

  pos = text.indexOf('">');

  let _cnt  = 0;

  while (pos > - 1) {
    if (!emptyValueValidation(sHrefPosition[_cnt]) && sHrefPosition[_cnt] < pos) {
      eHrefPosition.push(pos);

      _cnt ++;
    }

    pos = text.indexOf('">', pos + 1);
  }

  pos = text.indexOf('src="');

  while (pos > - 1) {
    sSrcPosition.push(pos);

    pos = text.indexOf('src="', pos + 1);
  }

  pos = text.indexOf('">');

  _cnt  = 0;

  while (pos > - 1) {
    if (!emptyValueValidation(sSrcPosition[_cnt]) && sSrcPosition[_cnt] < pos) {
      eSrcPosition.push(pos);

      _cnt ++;
    }

    pos = text.indexOf('">', pos + 1);
  }

  let content = text;

  for (let j = sHrefPosition.length - 1; j >= 0; j--) {
    const url = content.substring(sHrefPosition[j], eHrefPosition[j]).replaceAll('href="', '');

    content = content.substring(0, sHrefPosition[j]) + "onclick=moveUrl(\'" + url + "\')" + content.substring(eHrefPosition[j] + 1, content.length);
  }

  for (let j = sSrcPosition.length - 1; j >= 0; j--) {
    const url = content.substring(sSrcPosition[j], eSrcPosition[j]).replaceAll('src="', '');

    content = content.substring(0, sSrcPosition[j]) + "onclick=photoOpen(\'" + url + "\') src='" + url + "'" + content.substring(eSrcPosition[j] + 1, content.length);
  }

  return content;
};

export const linkCopy = props => {
  server.sendPost(
    '/mendle/v1/share/callback',
    {
      "contentsType": props.contentsType, // 사용자 정의 파라미터 설정
      "shareType": 'copy', // 사용자 정의 파라미터 설정
      "userUuid": '',
    },
    props.cbSuccess
  ).then();
};

export const naverTransformLog = transForm => {
  const script = document.createElement("script");

  script.type = 'text/javascript';
  script.id='naverTransformLog';
  script.append(
    "var _nasa={};\n\n" +
    "if (window.wcs) _nasa[\"cnv\"] = wcs.cnv(\"" + transForm + "\",\"0\");"
  )

  document.head.appendChild(script);
};

export const naverCommonLog = () => {
  const script = document.createElement("script");

  script.type = 'text/javascript';
  script.id='naverCommonLog';
  script.append(
      "if (!wcs_add) var wcs_add={};\n\n" +
      "wcs_add[\"wa\"] = \"s_3dffd4076f82\";\n\n" +
      "if (!_nasa) var _nasa={};\n\n" +
      "if (window.wcs) {\n" +
      " wcs.inflow();\n" +
      " wcs_do(_nasa);\n" +
      "}\n\n" +
      "var _nasa={};"
  )

  document.head.appendChild(script);
};
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useDidMountEffect from 'components/bb/hooks/useDidMountEffect';
import Cookies from 'universal-cookie';
import moment from 'moment/moment';

/* CSS */
import styles from 'components/bb/common/css/common.module.css';
import main from 'components/bb/common/css/main.module.css';

/* 공통 JS */
import * as server from 'components/common/server';
import * as Common from 'components/common/Common';
import * as native from 'components/common/native';

/* 메인 페이지 사용 컨텐츠 컴포넌트 */
import CateNav from 'components/bb/components/main/cateNav';
import RecommendShop from 'components/bb/components/main/recommandShop';
import NewEntered from 'components/bb/components/main/newEntered';
import RealReview from 'components/bb/components/main/realReview';
import PopEvent from 'components/bb/components/main/popEvent';
import RecentShop from 'components/bb/components/main/recentShop';
import MainNotice from 'components/bb/components/main/noticeList';
import Footer from 'components/bb/components/layout/footer';

import RpsPop from 'components/bb/components/pop/rpsPop';
import ErrorPop from 'components/bb/components/pop/errorPop';

import FixedBar from 'components/bb/components/layout/fixedbar';
import HomeTop from 'components/bb/components/layout/HomeTop';

/* 슬라이드 - 스와이퍼 */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'components/bb/common/css/swiper-pagination.css';

/* 모달 메세지 */
import ModalMsg from 'components/bb/components/modal/ModalMessage';
import { naverCommonLog } from 'components/common/Common';
import CouponPop from 'components/bb/components/pop/couponPop';

const Home = () => {
  // const cookies = new Cookies();
  // const navigate = useNavigate();
  const mainRef = useRef();

  // const [mainList, setMainList] = useState({
  //   beautyBookEvent: [],
  //   categoryMain: [],
  //   notice: [],
  //   popularityEvent: [],
  //   realReview: [],
  //   storeNew: [],
  //   storeRecent: [],
  //   storeRecommend: [],
  //   subBanner: [],
  //   specialExhibition: [],
  //   mdPick: [],
  //   rpsEvent: 0,
  // });
  //
  // const { geolocation } = navigator;
  // const [latitude, setLatitude] = useState('');
  // const [longitude, setLongitude] = useState('');
  //
  // const [appVersion, setAppVersion] = useState('');
  //
  // const [modalOpen, setModalOpen] = useState(false);
  // const [modalMsg, setModalMsg] = useState('');
  // const [showPop, setShowPop] = useState(false);
  // const [HOME_VISITED, setHOME_VISITED] = useState();

  // useDidMountEffect(() => {
  //   const today = moment(moment().format('YYYY-MM-DD HH:mm:ss')).valueOf();
  //
  //   const handlePop = () => {
  //     if ((HOME_VISITED && HOME_VISITED > today) || mainList.rpsEvent !== 0) {
  //       return;
  //     }
  //
  //     if (!HOME_VISITED || HOME_VISITED < today) {
  //       setShowPop(true);
  //     }
  //   };
  //
  //   window.setTimeout(handlePop, 1000);
  // }, [HOME_VISITED]);

  // useDidMountEffect(() => {
  //   if (showPop) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'unset';
  //   }
  // }, [showPop]);
  //
  // const getVersion = option => {
  //   setAppVersion(option);
  // };
  //
  // const appCallback = {
  //   receiveVersion: getVersion,
  // };
  //
  // window.appCallback = appCallback;
  //
  // useEffect(() => {
  //   cookies.remove('paymentProduct');
  //   cookies.remove('paymentProductUuidList');
  //   cookies.remove('paymentProductTotalPrice');
  //   cookies.remove('paymentProductPrice');
  //
  //   if (Common.isAndroid || Common.isIOS) {
  //     native.appRequest.getAppVersion();
  //   }
  //
  //   if (!Common.emptyValueValidation(cookies.get('accessToken'))) {
  //     server.sendGet('/mendle/v1/user/userInfo', {}, getUserInfo).then();
  //   } else {
  //     geolocation.getCurrentPosition(handleSuccess, handleError);
  //   }
  // }, []);
  //
  // useDidMountEffect(() => {
  //   callGetUserMain();
  // }, [longitude]);
  //
  // const getUserInfo = res => {
  //   if (res['apiCode'] === 'S001') {
  //     const { userType } = res['resultObject'];
  //
  //     if (userType === 'U') {
  //       geolocation.getCurrentPosition(handleSuccess, handleError);
  //     } else {
  //       navigate('/admin');
  //     }
  //   } else {
  //     cookies.remove('refreshToken');
  //     cookies.remove('accessToken');
  //     cookies.remove('loginStatus');
  //
  //     navigate('/login');
  //   }
  // };
  // const handleSuccess = pos => {
  //   setLatitude(pos?.coords?.latitude);
  //   setLongitude(pos?.coords?.longitude);
  // };
  // const handleError = () => {
  //   setLatitude('37.16277445220317');
  //   setLongitude('127.08914500275934');
  // };
  // const callGetUserMain = () => {
  //   server
  //     .sendGet(
  //       '/mendle/v1/main/user',
  //       {
  //         storeOffset: 0,
  //         storeUuidList: cookies.get('shopList'),
  //         reviewOffset: 0,
  //         eventOffset: 0,
  //         eventLimit: 3,
  //         specialExhibitionLimit: 10,
  //         mdPickLimit: 10,
  //         lat: latitude,
  //         lng: longitude,
  //         distance: 10,
  //       },
  //       getUserMain
  //     )
  //     .then();
  // };
  // const getUserMain = res => {
  //   setMainList(res['resultObject']);
  //   setHOME_VISITED(localStorage.getItem('homeVisited'));
  // };
  // const onMainBanner = (link, eventUuid) => {
  //   navigate(link + '?eventUuid=' + eventUuid);
  // };
  // const onModal = val => {
  //   setModalOpen(val);
  // };
  //
  // useDidMountEffect(() => {
  //   if (Common.isAndroid) {
  //     if (appVersion === '3.0.1') {
  //       setModalOpen(true);
  //       setModalMsg('뷰티북의 새로운 버전이 있습니다. 업데이트를 해주세요.');
  //     }
  //   }
  //
  //   if (Common.isIOS) {
  //     if (appVersion === '2.1.0') {
  //       setModalOpen(true);
  //       setModalMsg('뷰티북의 새로운 버전이 있습니다. 업데이트를 해주세요.');
  //     }
  //   }
  //
  //   // const param = {
  //   //   version: appVersion,
  //   //   isForced: "Y",
  //   //   message: "업데이트 대상",
  //   // };
  //   // native.appRequest.checkAppVersion(param);
  // }, [appVersion]);
  //
  // useDidMountEffect(() => {
  //   if (!modalOpen) {
  //     if (Common.isAndroid) {
  //       native.appRequest.openUrlEx('https://play.google.com/store/apps/details?id=com.mendle.mendleapp');
  //     }
  //
  //     if (Common.isIOS) {
  //       native.appRequest.openUrlEx('https://apps.apple.com/kr/app/%EB%A7%A8%EB%93%A4%EB%A7%A8%EB%93%A4/id1448628390');
  //     }
  //   }
  // }, [modalOpen]);

  return (
    <>
      {/*{modalOpen && <ModalMsg msg={modalMsg} function={onModal} />}*/}
      {/*{showPop && <CouponPop setShowPop={setShowPop} />}*/}
      {/*{showPop && <RpsPop setShowPop={setShowPop} />}*/}
      <div className={styles.renewal_container} ref={mainRef}>
        <img src="/assets/img/renewal.png"/>
        {/*/!**/}
        {/*<Helmet>*/}
        {/*  <meta charSet="utf-8" />*/}
        {/*  <title>뷰티북 - 홈</title>*/}
        {/*  <meta name="description" content="뷰티북 - 당신을 위한 뷰티 예약 플랫폼" data-react-helmet="true" />*/}
        {/*  <meta name="Keywords" content="뷰티북,뷰티, 뷰티 예약, 예약 플랫폼," data-react-helmet="true" />*/}
        {/*  <meta name="author" content="유에스티" data-react-helmet="true" />*/}
        {/*  <meta property="og:title" content="뷰티북 - 당신을 위한 뷰티 예약 플랫폼" data-react-helmet="true" />*/}
        {/*  <meta property="og:image" content="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/logo_ic.svg" data-react-helmet="true" />*/}
        {/*  <meta property="og:description" content="당신을 위한 뷰티 예약 플랫폼 지금 이용해보세요." data-react-helmet="true" />*/}
        {/*  <meta property="og:url" content="url" data-react-helmet="true" />*/}
        {/*</Helmet>*/}
        {/* *!/*/}
        {/*<HomeTop />*/}

        {/*<div className={`${styles.beautyWrap} ${styles.fixed} ${styles.bgGray}`}>*/}
        {/*  /!* 메인 비주얼 슬라이더 *!/*/}
        {/*  <div>*/}
        {/*    <Swiper loop={true} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Autoplay]} className={main.slideCon}>*/}
        {/*      {mainList.beautyBookEvent.map((item, index) => (*/}
        {/*        <SwiperSlide key={index} onClick={() => onMainBanner(item.bannerLink, item.eventUuid)}>*/}
        {/*          <img src={item.eventThumImg} alt="이벤트 배너" />*/}
        {/*        </SwiperSlide>*/}
        {/*      ))}*/}
        {/*    </Swiper>*/}
        {/*  </div>*/}

        {/*  /!* 카테고리 네비게이션 *!/*/}
        {/*  <CateNav item={mainList.categoryMain} />*/}

        {/*  <div className={main.shopContent}>*/}
        {/*    /!* MD's Pick *!/*/}
        {/*    /!*<MDsPick item={mainList?.mdPick} />*!/*/}

        {/*    /!* 추천 샵 *!/*/}
        {/*    <RecommendShop item={mainList.storeRecommend} />*/}

        {/*    /!* 신규 입점 샵 *!/*/}
        {/*    <NewEntered item={mainList.storeNew} />*/}

        {/*    /!* 리뷰 *!/*/}
        {/*    <RealReview item={mainList.realReview} />*/}

        {/*    /!* 배너 *!/*/}
        {/*    <div className={main.bannerSec}>*/}
        {/*      <Swiper loop={true} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Autoplay]} className={main.slideCon}>*/}
        {/*        {mainList.subBanner.map((item, index) => (*/}
        {/*          <SwiperSlide key={index}>*/}
        {/*            <div*/}
        {/*              className={main.slideSubBG}*/}
        {/*              style={{*/}
        {/*                backgroundImage: 'url(' + item['bannerUrl'] + ')',*/}
        {/*                backgroundSize: '100% 100%',*/}
        {/*                backgroundRepeat: 'no-repeat',*/}
        {/*              }}*/}
        {/*            />*/}
        {/*          </SwiperSlide>*/}
        {/*        ))}*/}
        {/*      </Swiper>*/}
        {/*    </div>*/}

        {/*    /!* 기획전 BEST *!/*/}
        {/*    /!*<BestProject item={mainList?.specialExhibition} />*!/*/}

        {/*    /!* 인기 이벤트 *!/*/}
        {/*    <PopEvent item={mainList.popularityEvent} />*/}

        {/*    /!* 최근본 샵 *!/*/}
        {/*    <RecentShop item={mainList.storeRecent} />*/}

        {/*    /!* 공지사항 *!/*/}
        {/*    <MainNotice item={mainList.notice} />*/}

        {/*    /!* 하단 푸터 *!/*/}
        {/*    <Footer />*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<FixedBar />*/}
      </div>
    </>
  );
};

export default Home;

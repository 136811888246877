import React, { useState, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';

/* CSS */
import styles from 'components/bb/common/css/common.module.css';

const ConfirmModalMsg = props => {
  const [open, setOpen] = useState(true);

  if (open === true) {
    document.body.style.overflow = 'hidden';
  }

  useEffect(() => {
    window.addEventListener('keypress', handleEventListener, false);

    return () => window.removeEventListener('keypress', handleEventListener);
  });

  const onClickModalBtn = () => {
    setOpen(prevValue => !prevValue);

    document.body.style.overflow = 'unset';

    props.function(true);
  };
  const onClickModalCancelBtn = () => {
    setOpen(prevValue => !prevValue);

    document.body.style.overflow = 'unset';

    props.function(false);
  };
  const handleEventListener = event => {
    if (event.keyCode === 13) {
      onClickModalBtn();
    }
  };

  return (
    <>
      {open && (
        <div className={`${isBrowser ? `${styles.browser} ${styles.modalMsg}` : styles.modalMsg}`}>
          <div className={styles.msgBox}>
            <div className={styles.msg}>
              <span dangerouslySetInnerHTML={{ __html: props.msg?.replaceAll('\n', '<br />') }}></span>
            </div>
            <button type="button" onClick={onClickModalCancelBtn} className={`${styles.msgTwoBtn} ${styles.cancel}`}>
              취소
            </button>
            <button type="button" onClick={onClickModalBtn} className={styles.msgTwoBtn}>
              확인
            </button>
          </div>
          <div className={styles.dimmed} />
        </div>
      )}
    </>
  );
};

export default ConfirmModalMsg;

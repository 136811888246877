import * as Common from "./Common";

export const postMessage = function(bridgeName, message) {
  if (Common.isAndroid){
    window.AOS[bridgeName](JSON.stringify(message));
  } else if (Common.isIOS) {
    window.IOS[bridgeName].postMessage(message);
  }
}

export const appRequest = {
  // json 데이타 저장
  // @param key : 저장키
  // @param data : 저장할 데이터
  "setJson" : props => {
    let message = {
      "key" : "loginInfo",
      "callback" : "appCallback.saveJsonResult",
      "data" : JSON.stringify(
        {
          "refreshToken" : props.refreshToken,
          "accessToken" : props.accessToken,
          "loginStatus" : props.loginStatus
        }
      )
    }
    postMessage("setJson", message);
  },

  // 저장한 json 데이타 불러오기
  // @param key : 저장키
  // @param callback : 응답받을 콜백함수 명
  // appCallback.getJson('{"id" : "aaaa","password" : "bbbb","autoLoginYn" : "Y", ...}')
  "getJson": () => {
    let message = {
      "key" : "loginInfo",
      "callback" : "appCallback.receiveJson"
    }
    postMessage("getJson",message);
  },

  //기기 AID(광고 아이디) 불러오기
  // @param callback : 응답받을 콜백함수 명
  // appCallback.getSSID('abkdickdje0987dc')
  "getSSID": () => {
    let message = {
      "callback" : "appCallback.receiveSSID"
    }
    postMessage("getSSID",message);
  },

  // 앱 버전 불러오기
  // @param callback : 응답받을 콜백함수 명
  // appCallback.getAppVersion('1.0.1')
  "getAppVersion": () => {
    let message = {
      "callback" : "appCallback.receiveVersion"
    }
    postMessage("getAppVersion",message);
  },

  //위치 정보 얻어오기
  //@param callback : 응답받을 콜백함수 명
  // appCallback.getAppVersion('{"lat":0.0, "lon":0.0, "addr": "경기도 안성시..."}')
  "getLocation": () => {
    let message = {
      "callback" : "appCallback.receiveLocation"
    }
    postMessage("getLocation", message)
  },

  // firebase token 불러오기
  // @param callback : 응답받을 콜백함수 명
  // appCallback.getPushToken('wef1f12-1d11g1g-12e12-f1f1g1')
  "getPushToken": () => {
    let message = {
      "callback" : "appCallback.receivePushToken"
    }
    postMessage("getPushToken",message);
  },

  // 앱 강제 종료
  "terminate" : (msg) => {
    let message = {
      "message" : msg
    }
    postMessage("terminate",message);
  },

  "setPushOnOff": props => {
    let message = {
      "message" : props
    }
    postMessage("setPushOnOff",message);
  },
  "openUrlEx": props => {
    let message = {
      "url" : props
    }
    postMessage("openUrlEx",message);
  },
  "checkAppVersion": props => {
    let message = {
      "version": props.version,
      "forceUpdate": props.isForced,
      "message": props.message,
    }
    postMessage("checkAppVersion",message);
  },
}
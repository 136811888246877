import React from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { BrowserView, MobileView } from 'react-device-detect';

const Loader = () => {
  return (
    <>
      <BrowserView>
        <LoadBox>
          <Oval height={80} width={80} color="#E03835" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel="oval-loading" secondaryColor="#E03835" strokeWidth={2} strokeWidthSecondary={2} />
        </LoadBox>
      </BrowserView>
      <MobileView>
        <MobLoadBox>
          <Oval height={80} width={80} color="#E03835" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel="oval-loading" secondaryColor="#E03835" strokeWidth={2} strokeWidthSecondary={2} />
        </MobLoadBox>
      </MobileView>
    </>
  );
};

const LoadBox = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  width: 460px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobLoadBox = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Loader;

import React, { useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import styled from 'styled-components'

/* CSS */
import styles from 'components/bb/common/css/common.module.css';

/* 공통 JS */
import * as server from 'components/common/server';
import * as Common from 'components/common/Common';

const PrivacyModal = props => {
    return (
        <>
            <div className={`${isBrowser ? `${styles.browser} ${styles.termModal}` : styles.termModal}`}>
                <div className={styles.header}>
                    <button className={styles.back} onClick={() => props.function()}>
                        <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/back_ic.svg" alt="뒤로가기" />
                    </button>
                    <h4>개인정보 이용약관</h4>
                </div>

                <div className={styles.termCon}>
                    <Inner>
                        <Title>개인정보 수집 및 이용 동의</Title>

                        <TableBox>
                            <PrivacyTable>
                                <colgroup>
                                    <col width='30%' />
                                    <col width='70%' />
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>구분</th>
                                    <th>필수 수집</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>개인정보 수집 및 이용 목적</td>
                                    <td>
                                        <ul>
                                            <li>회원제 서비스의 제공을 위한 이용자 식별, 계약 이행을 위한 연락, 민원 해결 등의 고충 처리, 개인화된 서비스 제공 및 추천/광고 게재, 회원의 서비스 이용에 대한 통계 등</li>
                                            <li>부정 가입 및 이용 방지 등</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>개인정보 수집 항목</td>
                                    <td>
                                        (필수) 성명, 아이디, 비밀번호, 휴대폰 번호, 쿠키(자동수집), 생년월일
                                    </td>
                                </tr>
                                <tr>
                                    <td>보유 및 이용 기간</td>
                                    <td>
                                        <ul>
                                            <li>회원 탈퇴 시 파기 처리</li>
                                            <li>부정 가입 및 이용 방지를 위해 탈퇴 회원 데이터는 최대 1년간 보관 후 파기 처리</li>
                                            <li>휴면회원 전환시 별도 분리 보관</li>
                                            <li>단, 관계 법령의 규정에 따라 보존할 의무가 있으면 해당 기간 동안 보조</li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </PrivacyTable>
                        </TableBox>
                        <DescBox>
                            <p>※ 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동으 거부 시 회원가입이 제한됩니다.</p>

                            <Rule>
                                <strong>부칙</strong>
                                <p>본 약관은 2018년 11월 1일 부터 시행됩니다.</p>
                            </Rule>
                        </DescBox>
                    </Inner>
                </div>
            </div>
        </>
    );
};

const Inner = styled.div`
  padding:20px 16px;
`

const Title = styled.strong`
  font-weight:700;
`

const TableBox = styled.div`
  margin-top:15px;
`

const PrivacyTable = styled.table`
  position:relative;
  border:1px solid #f4f4f4;
  
  & th {
    text-align:center;
    padding:10px 0;
    font-weight:700;
    font-size:16px;
  }
  
  & th + th {
    border-left:1px solid #f4f4f4;
  }
  
  & tr td {
    border-top:1px solid #f4f4f4;
    padding:10px;
    font-weight:400;
    font-size:13px;
    line-height:18px;
    
    & ul li {
      margin-left:20px;
      list-style:disc;
    }
    
    & ul li::marker {
      font-size:10px;
    }
    
    & li + li {
      margin-top:5px;
    }
  }
  
  & tr td + td {
    border-left:1px solid #f4f4f4;
  }
`

const DescBox = styled.div`
  margin-top:20px;
  
  & p {
    font-weight:400;
    font-size:14px;
    line-height:20px;
  }
`

const Rule = styled.div`
  margin-top:20px;
  
  & strong {
    font-weight:700;
    font-size:16px;
  }
  
  & p {
    margin-top:15px;
    display:block;
    font-weight:400;
    font-size:14px;
    line-height:20px;
  }
`

export default PrivacyModal;

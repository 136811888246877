import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import styled, { keyframes } from 'styled-components';
import CountUp from 'react-countup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Cookies from 'universal-cookie';
import { ReactSVG } from 'react-svg';

/* 공통 JS */
import * as server from 'components/common/server';
import * as Common from 'components/common/Common';

/* 모달 메세지 */
import ModalMsg from 'components/bb/components/modal/FullPageModalMessage';
import axios from 'axios';

const RPS = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const interval = useRef(null);
  const [searchParams] = useSearchParams();

  const [computer, setComputer] = useState('rock');
  const [user, setUser] = useState('rock');
  const [game, setGame] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [round, setRound] = useState(0);
  const [coupon, setCoupon] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const [isCoupon, setIsCoupon] = useState(false);

  const changeHand = useCallback(() => {
    if (computer === 'rock') {
      setComputer('scissor');
    } else if (computer === 'scissor') {
      setComputer('paper');
    } else if (computer === 'paper') {
      setComputer('rock');
    }
  }, [computer]);
  const gameStart = () => {
    setModalOpen(true);
    setModalMsg('종료된 이벤트입니다.');
    return;
    if (Common.emptyValueValidation(cookies.get('accessToken'))) {
      setModalOpen(true);
      setModalMsg('로그인 후 이용가능합니다.');
    } else {
      couponExist();
    }
  };
  const ReSet = () => {
    setRound(0);
    setGame(false);
    setCoupon(false);
  };
  const onClickBtn = user => () => {
    if (game === true) {
      setGame('result');
      setUser(user);
      setBtnDisabled(true);
      setRound(round + 1);
    }
  };
  const couponExist = () => {
    server.sendGet('/mendle/v1/coupon/isCouponExist', {}, isCouponExist).then();
  };
  const isCouponExist = res => {
    if (res['apiCode'] === 'S001') {
      if (res['resultCount'] !== 0) {
        setModalOpen(true);
        setModalMsg('이미 쿠폰을 수령한 사용자입니다.');
      } else {
        setGame(true);
      }
    }
  };
  const onCouponIssue = () => {
    server
      .sendPost(
        '/mendle/v1/coupon/newMember',
        {
          eventUuid: searchParams.get('eventUuid'),
        },
        giveCoupon
      )
      .then();
  };

  const giveCoupon = res => {
    if (res['apiCode'] === 'S001') {
      if (res['resultCount'] !== 0) {
        setIsCoupon(true);
        setModalOpen(true);
        setModalMsg('쿠폰이 발급되었습니다.');
      }
    }
  };

  useEffect(() => {
    interval.current = setInterval(changeHand, 100);

    return () => {
      clearInterval(interval.current);
    };
  }, [changeHand]);

  useEffect(() => {
    document.body.style.overflow = 'unset';
  }, []);

  const KakaoShare = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init(process.env.REACT_APP_KAKAO_MAP_APP_KEY);
      }

      kakao.Share.sendCustom({
        templateId: 95720,
        serverCallbackArgs: {
          contentsType: 'rps', // 사용자 정의 파라미터 설정
          shareType: 'KAKAO', // 사용자 정의 파라미터 설정
          userUuid: '',
        },
      });
    }
  };

  const shareCallback = () => {
    setModalOpen(true);
    setModalMsg('링크가 복사되었습니다.');
  };

  const url = window.location.href;

  const onModal = val => {
    setModalOpen(val);

    if (isCoupon) {
      navigate('/mypage/coupon');
    }
  };

  return (
    <LandingWrap>
      <LandingTop>
        {modalOpen && <ModalMsg msg={modalMsg} function={onModal} />}
        <BrowserView>
          <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_bg01.png" alt="pc 비주얼" />
        </BrowserView>
        <MobileView>
          <MobileHeader>
            <button onClick={() => navigate('/')}>
              <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/back_ic.svg" alt="뒤로가기" />
            </button>
            <h4>티부를 이겨라</h4>
          </MobileHeader>
          <MobileVisual>
            <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_bg01m.png" alt="mo 비주얼" />
          </MobileVisual>
        </MobileView>
      </LandingTop>
      <LandingContent>
        <LandingGame>
          <RockBox>
            {game === true && (
              <RockGame>
                {computer === 'rock' && <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_rock.svg" alt="바위" />}
                {computer === 'scissor' && <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_scissor.svg" alt="가위" />}
                {computer === 'paper' && <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_paper.svg" alt="보" />}
              </RockGame>
            )}

            {game === false && (
              <GameOff>
                <StartBox>
                  <button onClick={gameStart}>
                    Game Start <p>화면을 누르면 시작합니다.</p>
                  </button>
                </StartBox>
                <RockGame>
                  <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_rock.svg" alt="바위" />
                </RockGame>
              </GameOff>
            )}
            <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_game.svg" alt="게임기" useMap="#image-map" />

            {game === 'result' && round > 0 && (
              <GameOn>
                <ResultBox>
                  <ResultDesc>
                    {round < 3 && (
                      <RoundDesc onClick={gameStart}>
                        <span>Game {round} Win</span>
                        <button>Round {round + 1} Start</button>
                      </RoundDesc>
                    )}

                    {round === 3 && (
                      <RoundDesc
                        onClick={() => {
                          setCoupon(state => !state);
                        }}
                      >
                        <span>Game End</span>
                        <button>결과 확인 후 쿠폰 받기</button>
                      </RoundDesc>
                    )}
                  </ResultDesc>
                </ResultBox>
                <RockGame>
                  {user === 'paper' && <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_rock.svg" alt="바위" />}
                  {user === 'rock' && <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_scissor.svg" alt="가위" />}
                  {user === 'scissor' && <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_paper.svg" alt="보" />}
                </RockGame>
              </GameOn>
            )}

            <TotalCount>
              <strong>누적 금액</strong>
              {round === 0 && <span>0</span>}
              {round === 1 && <CountUp start={0} end={3000} />}
              {round === 2 && <CountUp start={3000} end={13000} />}
              {round === 3 && <CountUp start={13000} end={33000} />}
            </TotalCount>
          </RockBox>
          <RpsContent>
            <RpsButton color="#f14337" onClick={onClickBtn('scissor')}>
              가위
            </RpsButton>
            <RpsButton color="#3786bc" onClick={onClickBtn('rock')}>
              바위
            </RpsButton>
            <RpsButton color="#7baa3f" onClick={onClickBtn('paper')}>
              보
            </RpsButton>
          </RpsContent>
        </LandingGame>

        {coupon && (
          <ResultModal>
            <ModalBox>
              <ModalDesc>
                <strong>축하드려요!</strong>
                <span>게임에 모두 이기셨어요!</span>
              </ModalDesc>
              <ModalCoupon>
                <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_coupon.svg" alt="33,000 쿠폰" />
                <button onClick={onCouponIssue}>
                  <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_downIc.svg" alt="Download Ic" />
                  쿠폰 다운받기
                </button>

                <span>
                  이미 쿠폰을 수령한 사용자는&nbsp;
                  <br />
                  참여대상에서 제외됩니다
                </span>
              </ModalCoupon>
              <ModalButton>
                <button onClick={ReSet}>닫기</button>
              </ModalButton>
            </ModalBox>
            <Dimmed />
          </ResultModal>
        )}
      </LandingContent>
      <LandingFooter>
        <FooterInner>
          <strong>참여 안내</strong>
          <ul>
            <li>본 이벤트는 1인 1회 참여 가능합니다.</li>
            <li>본 이벤트는 신규가입 회원 대상이며, 기존 맨들맨들 가입 이력이 있는고객은 참여가 제한됩니다.</li>
            <li>본 쿠폰의 유효기간은 2023년 12월 31일 입니다.</li>
            <li>각 쿠폰별 최소주문금액이 상이하므로 쿠폰정보를 통해 확인 바랍니다.</li>
            <li>쿠폰은 [마이페이지 > 쿠폰] 내에서 확인 가능합니다.</li>
            <li>본 쿠폰은 프로모션 상품으로 유효기간 연장 및 환불대상이 아닙니다.</li>
            <li>본 쿠폰은 뷰티북 기타이벤트 및 할인 등과 중복할인이 불가합니다.</li>
            <li>쿠폰사용 및 이벤트 문의 : 010-9644-6694</li>
          </ul>

          <ShareDesc>
            <button onClick={KakaoShare}>
              <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/kakao_share.svg" alt="카카오톡 공유" />
            </button>
            <CopyToClipboard text={url}>
              <button
                onClick={() =>
                  Common.linkCopy({
                    contentsType: 'rps',
                    cbSuccess: shareCallback,
                  })
                }
              >
                <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/link_share.svg" alt="링크 공유" />
              </button>
            </CopyToClipboard>
          </ShareDesc>
        </FooterInner>
      </LandingFooter>
    </LandingWrap>
  );
};

const LandingWrap = styled.div`
  position: relative;

  & * {
    font-family: 'Noto Sans KR';
  }
`;
const LandingTop = styled.div`
  position: relative;

  & img {
    width: 100%;
  }
`;

const MobileHeader = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 58px;
  background: #ffffff;
  box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
  }

  & h4 {
    font-weight: 700;
    font-size: 20px;
    color: #242424;
  }
`;

const MobileVisual = styled.div`
  position: relative;
  margin-top: 58px;
`;

const LandingContent = styled.div`
  margin-top: -2px;
  padding-top: 120px;
  position: relative;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_bg02.svg') no-repeat;
  background-size: cover;
  text-align: center;

  @media screen and (max-width: 1020px) {
    padding-top: 45px;
  }
`;

const LandingGame = styled.div`
  position: relative;
  display: inline-block;
  max-width: 100%;
  margin: 0 auto;

  & img {
    width: 777px;
  }

  @media screen and (max-width: 1020px) {
    padding: 0 16px;

    & img {
      width: 100%;
    }
  }
`;

const TotalCount = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  z-index: 3;
  display: flex;
  width: 50%;
  align-items: center;
  background: #171717;
  border: 1px solid #ffffff;
  & strong {
    flex: 1;
    display: block;
    padding: 10px 18px;
    background: #000000;
    font-family: 'NeoDgm';
    font-weight: 400;
    font-size: 28px;
    color: #ffffff;
  }

  & span {
    border-left: 1px solid #ffffff;
    flex: 1;
    display: block;
    padding: 10px 18px;
    font-family: 'NeoDgm';
    font-weight: 400;
    font-size: 28px;
    color: #e7a600;
    text-align: right;
  }

  @media screen and (max-width: 740px) {
    & strong,
    span {
      padding: 10px 14px;
      font-size: 20px;
    }
  }

  @media screen and (max-width: 540px) {
    & strong,
    span {
      padding: 5px 10px;
      font-size: 15px;
    }
  }

  @media screen and (max-width: 425px) {
    & strong,
    span {
      padding: 5px 8px;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 340px) {
    top: 19%;

    & strong,
    span {
      font-size: 10px;
    }
  }
`;

const RockBox = styled.div`
  margin-top: 30px;
  position: relative;
  text-align: center;
  display: inline-block;

  & area {
    cursor: pointer;
  }
`;

const GameOff = styled.div``;

const GameOn = styled.div``;

const Fade = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StartBox = styled.div`
  z-index: 3;
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 62%;
  height: 40.5%;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 18px;

  & button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    width: 100%;
    color: #ffffff;
    font-family: 'NeoDgm';
    font-weight: 400;
    font-size: 48px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    & p {
      position: relative;
      display: block;
      color: #ffffff;
      font-family: 'NeoDgm';
      font-weight: 400;
      font-size: 16px;
      animation: ${Fade} 3s 1s infinite linear alternate;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: rotate(-30deg);
        width: 36px;
        height: 36px;
        background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/pixel_cursor.svg') no-repeat;
        background-size: 36px 36px;
        opacity: 0.9;
      }
    }
  }

  @media screen and (max-width: 740px) {
    & button {
      font-size: 34px;
    }
  }

  @media screen and (max-width: 540px) {
    & button {
      font-size: 24px;
      & p {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 380px) {
    & button {
      font-size: 18px;
      & p {
        font-size: 12px;

        &::before {
          width: 24px;
          height: 24px;
          background-size: 24px 24px;
        }
      }
    }
  }
`;

const ResultBox = styled.div`
  z-index: 3;
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 40.5%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 18px;
`;

const ResultDesc = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const RoundDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & span {
    color: #ffffff;
    font-family: 'NeoDgm';
    font-weight: 400;
    font-size: 48px;
    text-align: center;
  }

  & button {
    position: relative;
    background: transparent;
    color: #e7a600;
    font-family: 'NeoDgm';
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    animation: ${Fade} 3s 1s infinite linear alternate;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: rotate(-30deg);
      width: 36px;
      height: 36px;
      background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/pixel_cursor.svg') no-repeat;
      background-size: 36px 36px;
      opacity: 0.9;
    }
  }

  @media screen and (max-width: 720px) {
    & span {
      font-size: 40px;
    }

    & button {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 500px) {
    & span {
      font-size: 32px;
    }

    & button {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 350px) {
    & span {
      font-size: 24px;
    }

    & button {
      font-size: 16px;

      &::before {
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
      }
    }
  }
`;

const RockGame = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 26.88%;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_coin.svg') no-repeat;
  background-size: cover;
  box-shadow: 0 0 20px 10px rgba(231, 166, 0, 0.15);
  border-radius: 50%;
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
`;

const RpsContent = styled.div`
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 30px;

  @media screen and (max-width: 720px) {
    gap: 0 16px;
  }

  @media screen and (max-width: 460px) {
    gap: 0 8px;
  }
`;

const RpsButton = styled.button`
  width: 270px;
  height: 60px;
  border-radius: 10px;
  background: ${props => props.color || '#ffffff'};
  font-family: NeoDgm;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  border: 5px solid #000000;
`;

const ResultModal = styled.div`
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

const ModalBox = styled.div`
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px 0;
  background: #a30e10;
  padding: 50px 0 0;

  @media (max-width: 1024px) {
    width: calc(100% - 48px);
  }
`;

const ModalDesc = styled.div`
  & strong {
    font-weight: 700;
    font-size: 60px;
    color: #ffffff;
  }

  & span {
    display: block;
    font-weight: 700;
    font-size: 45px;
    line-height: 60px;
    color: #ffffff;
  }

  @media (max-width: 540px) {
    & strong {
      font-size: 48px;
    }

    & span {
      font-size: 32px;
      line-height: 44px;
    }
  }

  @media (max-width: 400px) {
    & strong {
      font-size: 36px;
    }

    & span {
      font-size: 24px;
      line-height: 36px;
    }
  }

  @media (max-width: 340px) {
    & strong {
      font-size: 28px;
    }

    & span {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const ModalCoupon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px 0;

  & > img {
    width: 80%;
  }

  & button {
    width: 80%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 16px;
    font-weight: 700;
    font-size: 32px;
    color: #000000;
    background: #f5bb4c;

    & img {
      width: 10%;
    }
  }

  & span {
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;

    & br {
      display: none;
    }
  }

  @media (max-width: 720px) {
    & span {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    & button {
      height: 60px;
      font-size: 20px;
    }

    & span {
      font-size: 12px;
    }
  }

  @media (max-width: 340px) {
    & span {
      line-height: 1.5;
      & br {
        display: block;
      }
    }
  }
`;
const ModalButton = styled.div`
  width: 100%;
  & button {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #ffffff;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
  }
`;
const Dimmed = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
`;

const LandingFooter = styled.div`
  position: relative;
  padding: 50px 0;
  background: #101010;
`;

const FooterInner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  background: #101010;
  & strong {
    font-weight: 700;
    font-size: 23px;
    color: #dddddd;
  }

  & ul {
    margin-top: 30px;
    & li {
      list-style: disc;
      margin-left: 30px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #767676;
      &::marker {
        font-size: 14px;
        vertical-align: middle;
      }
    }
  }

  @media screen and (max-width: 720px) {
    & ul li {
      font-size: 17px;
      &::marker {
        font-size: 12px;
        vertical-align: middle;
      }
  }

  @media screen and (max-width: 480px) {
    & ul li {
      margin-left:20px;
      font-size: 14px;
      &::marker {
        font-size: 12px;
        vertical-align: middle;
      }
  }
`;

const ShareDesc = styled.div`\
  position:absolute;
  right:18%;
  bottom:50px;
  display: flex;
  align-items: center;
  gap: 0 16px;

  & button {
    width: 45px;
    height: 45px;
    & img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1020px) {
    position:fixed;
    right:20px;
    flex-direction:column;
    gap:16px 0;
  }
`;

export default RPS;

const { kakao } = window;

const kakaoMapList = (map, markers, lat, lng, positions, current, swiper) => {
  map.setCenter(new kakao.maps.LatLng(lat, lng));
  map.setLevel(5);

  markers.markers.filter(el => {
    el.setMap(null);
  });

  markers.customOverlay.filter(el => {
    el.setMap(null);
  });

  const markerList = {
    markers: [],
    customOverlay: [],
  };

  let imageSrc = 'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/detail/userMarker_ic.svg';

  const imageSize = new kakao.maps.Size(22.95, 28.65);
  const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);
  const marker = new kakao.maps.Marker({
    map: map, // 마커를 표시할 지도
    position: new kakao.maps.LatLng(lat, lng), // 마커를 표시할 위치
    image: markerImage, // 마커 이미지
    clickable: true, // 마커를 클릭했을 때 지도의 클릭 이벤트가 발생하지 않도록 설정합니다
    dbClickable: true,
  });

  marker.setMap(map);

  markerList.markers.push(marker);

  imageSrc = 'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/detail/storeMarker_ic.svg';

  positions.forEach((el, index) => {
    const imageSize = new kakao.maps.Size(10, 10);
    const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);
    const marker = new kakao.maps.Marker({
      map: map,
      position: new kakao.maps.LatLng(el.lat, el.lng),
      image: markerImage,
      clickable: true,
      dbClickable: true,
    });
    const customOverlay = new kakao.maps.CustomOverlay({
      content: `
      <div class="mapPin"><a href='/detail?storeUuid=${el.storeUuid}' class="link">${el.storeName}</a></div>
        `,
      position: marker.getPosition(),
    });

    markerList.markers.push(marker);
    markerList.customOverlay.push(customOverlay);

    if (el.open) {
      map.setCenter(new kakao.maps.LatLng(el.lat, el.lng));
      map.setLevel(4);

      customOverlay.setMap(map);
    }

    kakao.maps.event.addListener(marker, 'click', makeClickListener(map, marker, customOverlay, el, index));
    kakao.maps.event.addListener(map, 'center_changed', makeChangeListener(map));
  });

  function makeClickListener(map, marker, customOverlay, el, index) {
    return function () {
      markerList.customOverlay.filter(el => {
        el.setMap(null);
      });

      customOverlay.setMap(map);

      map.setCenter(new kakao.maps.LatLng(el.lat, el.lng));
      map.setLevel(2);

      swiper(index);
    };
  }

  function makeChangeListener(map) {
    return function () {
      const latLng = map.getCenter();

      current(latLng.getLat(), latLng.getLng());
    };
  }

  return markerList;
};

export default kakaoMapList;

const { kakao } = window;

const kakaoMap = props => {
  const container = document.getElementById('map');
  const options = {
    center: new kakao.maps.LatLng(props.lat, props.lng),
    level: 3,
  };
  const map = new kakao.maps.Map(container, options);

  map.setCenter(new kakao.maps.LatLng(props.lat, props.lng));

  let imageSrc = 'https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/detail/storeMarker_ic.svg';

  const imageSize = new kakao.maps.Size(10, 10);
  const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);
  const marker = new kakao.maps.Marker({
    map: map, // 마커를 표시할 지도
    position: new kakao.maps.LatLng(props.lat, props.lng), // 마커를 표시할 위치
    image: markerImage, // 마커 이미지
  });
  const customOverlay = new kakao.maps.CustomOverlay({
    content: `<div class="mapPin">${props.storeName}</div>`,
    position: marker.getPosition(),
  });

  customOverlay.setMap(map);
};

export default kakaoMap;

import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { BrowserView, MobileView } from 'react-device-detect';
import axios from 'axios';

/* 구글 애널리틱스 */
import ReactGA from 'react-ga';

/* CSS */
import GlobalStyles from 'components/common/GlobalStyles';

/* 공통 JS */
import * as Common from 'components/common/Common';

/* 채널톡 */
import { ReactChannelIO } from 'react-channel-plugin';
import { CHANNEL_ID_LANG, CHANNEL_ID_PLUGIN_KEY_USER, CHANNEL_ID_PLUGIN_KEY_SHOP } from './config';

/* 모달 메세지 */
import ConfirmModalMsg from 'components/bb/components/modal/ConfirmModalMessage';

/* BB(BeautyBook) 뷰티북 */
import PcLayOut from 'components/bb/components/layout/pcLayout';
import Home from 'components/bb/page/main';
import EnteredShop from 'components/bb/page/mypage/entered';
import PushMsg from 'components/bb/page/mypage/pushMsg';
import Login from 'components/bb/page/member/login';
import KaKaoLogin from 'components/bb/page/member/kakaoLogin';
import NaverLogin from 'components/bb/page/member/naverLogin';
import AppleLogin from 'components/bb/page/member/appleLogin';
import AppleJoinChk from 'components/bb/page/member/appleJoinChk';
import Join from 'components/bb/page/member/join';
import SocialJoin from 'components/bb/page/member/socialJoin';
import AppleJoin from 'components/bb/page/member/applelJoin';
import AppleJoinTemp from 'components/bb/page/member/applelJoinTemp';
import FindId from 'components/bb/page/member/findId';
import FindPw from 'components/bb/page/member/findPw';
import IdResult from 'components/bb/page/member/idResult';
import PwResult from 'components/bb/page/member/pwResult';
import Mypage from 'components/bb/page/mypage/index';
import Myinfo from 'components/bb/page/mypage/myinfo';
import Account from 'components/bb/page/mypage/account';
import InfoMobile from 'components/bb/page/mypage/infoMobile';
import Setting from 'components/bb/page/mypage/setting';
import Wish from 'components/bb/page/mypage/wish';
import Terms from 'components/bb/page/mypage/terms';
import TermsDetail from 'components/bb/page/mypage/terms/detail';
import PrivacyUse from "./components/bb/page/mypage/privacy";
import Notice from 'components/bb/page/mypage/notice';
import NoticeDetail from 'components/bb/page/mypage/noticeDetail';
import Coupon from 'components/bb/page/mypage/coupon';
import Mileage from 'components/bb/page/mypage/mileage';
import PwChange from 'components/bb/page/mypage/pwChange';
import PhoneChange from 'components/bb/page/mypage/phoneChange';
import Qna from 'components/bb/page/mypage/qna';
import Comment from 'components/bb/page/review/comment';
import Write from 'components/bb/page/review/write';
import Modify from 'components/bb/page/review/modify';
import Booking from 'components/bb/page/booking/book';
import BookingChk from 'components/bb/page/booking/bookChk';
import ShopList from 'components/bb/page/shop/shopList';
import ShopMap from 'components/bb/page/shop/shopMap';
import NoTopShopList from 'components/bb/page/shop/noTopShopList';
import SearchResult from 'components/bb/page/search/searchResult';
import ShopDetail from 'components/bb/page/shop/detail';
import ReviewHome from 'components/bb/page/review/index';
import ReviewDetail from 'components/bb/page/review/detail';
import MagazineHome from 'components/bb/page/magazine/index';
import MagazineDetail from 'components/bb/page/magazine/detail';
import MagazineComment from 'components/bb/page/magazine/comment';
import BeautyNewsDetail from 'components/bb/page/beautyNews/detail';
import BeautyNewsComment from 'components/bb/page/beautyNews/comment';
import MdPickDetail from 'components/bb/page/mdPick/detail';
import MdPickComment from 'components/bb/page/mdPick/comment';
import EventHome from 'components/bb/page/event/index';
import EventDetail from 'components/bb/page/event/detail';
import EventComment from 'components/bb/page/event/comment';
import ReservationChange from 'components/bb/page/reservation/change';
import ReservationDate from 'components/bb/page/reservation/date';
import ReservationProduct from 'components/bb/page/reservation/product';
import ReservationPay from 'components/bb/page/reservation/payment';
import ProjectList from 'components/bb/page/project/list';
import WritableReview from 'components/bb/page/mypage/writable';
import MyReviewChk from 'components/bb/page/mypage/myreview';
import Loader from 'components/bb/components/layout/loading';
import RPS from 'components/bb/page/event/landing/rock';
import InfoLanding from 'components/bb/page/event/landing/info';
import AdminInfoLanding from 'components/bb/page/event/landing/adminInfo';
import EventDetailSummer from 'components/bb/page/event/landing/summer';
import EventDetailAppReview from 'components/bb/page/event/landing/appReview';
import CouponDetail from 'components/bb/page/event/landing/coupon';
import QR from 'components/beauty-bookShare/shareQR';

/* 뷰티북 어드민 페이지 */
import AdminHome from 'components/bb/page/admin/index';
import AdminMypage from 'components/bb/page/admin/mypage';
import AdminBook from 'components/bb/page/admin/booking/adminBook';
import AdminBookChk from 'components/bb/page/admin/booking/adminBookChk';
import AdminReview from 'components/bb/page/admin/review/adminReview';
import AdminReservationChange from 'components/bb/page/admin/reservation/change';
import AdminCalendar from 'components/bb/page/admin/reservation/calendar';
import AdminQna from 'components/bb/page/admin/qna';

/* office - 심리 테스트 - 직장 동료가 보는 내 모습 */
import OHome from 'components/mentality/home/home';
import OTest from 'components/mentality/test/test';
import OResult from 'components/mentality/result/result';

/* king - 심리 테스트 - 킹력테스트 */
import KHome from 'components/king/home/home';
import KTest from 'components/king/test/test';
import KResult from 'components/king/result/result';
import KResultAll from 'components/king/result/resultAll';

const App = () => {
  const cookies = new Cookies();
  const history = useNavigate();
  const location = useLocation();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const [userType, setUserType] = useState('');

  const [loading, setLoading] = useState(false);

  // const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;

  // ReactGA.initialize(gaTrackingId);

  // axios.interceptors.response.use(
  //   response => {
  //     setLoading(false);
  //
  //     return response;
  //   },
  //   async error => {
  //     setLoading(false);
  //
  //     return Promise.reject(error);
  //   }
  // );

  // useEffect(() => {
  //   axios.interceptors.request.use(
  //     function (config) {
  //       const notLoadingUrl = ['s3upload', 'userInfo', 'like'];
  //
  //       if (!loading && !notLoadingUrl.includes(config.url)) {
  //         setLoading(true);
  //       }
  //
  //       return config;
  //     },
  //     function (error) {
  //       return Promise.reject(error);
  //     }
  //   );
  // });

  useEffect(
    pathname => {
      history('/home');
      // document.querySelector(`script[id='naverTransformLog']`)?.remove();
      // document.querySelector(`script[id='naverCommonLog']`)?.remove();
      //
      // Common.naverCommonLog();
      //
      // ReactGA.set({ page: location.pathname });
      // ReactGA.pageview(location.pathname + location.search);
      //
      // window.scrollTo(0, 0);
      //
      // const channelUrl = ['/mypage', '/admin', '/admin/mypage'];
      //
      // if (channelUrl.includes(location.pathname)) {
      //   setUserType(location.pathname === '/mypage' ? 'U' : 'M');
      // }
      //
      // if (location.pathname.search('/magazine') > -1 || location.pathname.search('/beautyNews') > -1 || location.pathname.search('/mypage/coupon') > -1) {
      //   document.querySelector(`script[id="naverCommonLog"]`)?.remove();
      //
      //   Common.naverTransformLog(5);
      //   Common.naverCommonLog();
      // }
      //
      // const url = [
      //   '/mypage',
      //   '/myinfo',
      //   '/mypage/mileage',
      //   '/mypage/coupon',
      //   '/mypage/writable',
      //   '/mypage/myreview',
      //   '/account',
      //   '/wish',
      //   '/pwChange',
      //   '/phoneChange',
      //   '/booking',
      //   '/booking/check',
      //   '/push',
      //   '/reservation/date',
      //   '/reservation/change',
      //   '/reservation/product',
      //   '/reservation/payment',
      // ];
      //
      // if (url.includes(location.pathname)) {
      //   if (location.pathname === '/reservation/date') {
      //     history('/home');
      //   }
      //
      //   if (Common.emptyValueValidation(cookies.get('accessToken'))) {
      //     history(-1);
      //
      //     setConfirmModalOpen(true);
      //     setModalMsg('로그인 후 이용가능합니다.\n로그인 하러 가기');
      //   }
      // }
      //
      // if (location.pathname.indexOf('/admin') > -1) {
      //   if (Common.emptyValueValidation(cookies.get('accessToken'))) {
      //     history('/home');
      //   }
      // }
    },
    [location]
  );

  const onConfirmModal = res => {
    setConfirmModalOpen(false);

    if (res) {
      history('/login');
    }
  };

  return (
    <>
      <ReactChannelIO
        pluginKey={userType === 'U' ? CHANNEL_ID_PLUGIN_KEY_USER : CHANNEL_ID_PLUGIN_KEY_SHOP}
        language={CHANNEL_ID_LANG}
        hideChannelButtonOnBoot={true}
        autoBoot
        mobileMessengerMode="iframe"
      >
        <BrowserView>
          <Routes>
            <Route element={<PcLayOut />}>
              {/* 뷰티북 유저 */}
              {/*<Route exact path="/mypage" element={<Mypage />} />*/}
              {/*<Route exact path="/myinfo" element={<Myinfo />} />*/}
              {/*<Route exact path="/mypage/coupon" element={<Coupon />} />*/}
              {/*<Route exact path="/mypage/mileage" element={<Mileage />} />*/}
              {/*<Route exact path="/review" element={<ReviewHome />} />*/}
              {/*<Route exact path="/mypage/writable" element={<WritableReview />} />*/}
              {/*<Route exact path="/mypage/myreview" element={<MyReviewChk />} />*/}
              {/*<Route exact path="/account" element={<Account />} />*/}
              {/*<Route path="/mypage/wish" element={<Wish />} />*/}
              {/*<Route path="/pwChange" element={<PwChange />} />*/}
              {/*<Route path="/phoneChange" element={<PhoneChange />} />*/}
              {/*<Route exact path="/mypage/booking" element={<Booking />} />*/}
              {/*<Route exact path="/booking/check" element={<BookingChk />} />*/}
              {/*<Route exact path="/magazine/comment" element={<MagazineComment />} />*/}
              {/*<Route exact path="/beautyNews/comment" element={<BeautyNewsComment />} />*/}
              {/*<Route exact path="/mdPick/comment" element={<MdPickComment />} />*/}
              {/*/!*<Route exact path="/reservation/date" element={<ReservationDate />} />*!/*/}
              {/*<Route exact path="/reservation/product" element={<ReservationProduct />} />*/}
              {/*<Route exact path="/reservation/payment" element={<ReservationPay />} />*/}
              {/*<Route exact path="/reservation/change" element={<ReservationChange />} />*/}
              {/*<Route exace path="/entered" element={<EnteredShop />} />*/}
              {/*<Route path="/push" element={<PushMsg />} />*/}

              {/* 뷰티북 유저 & 둘러보기 */}
              <Route path="/home" element={<Home />} />
              {/*<Route path="/" element={<Home />} />*/}
              {/*<Route exact path="/shop" element={<ShopList />} />*/}
              {/*<Route exact path="/project" element={<ProjectList />} />*/}
              {/*<Route exact path="/shopMap" element={<ShopMap />} />*/}
              {/*<Route exact path="/detail" element={<ShopDetail />} />*/}
              {/*<Route exact path="/CustomShop" element={<NoTopShopList />} />*/}
              {/*<Route exact path="/event" element={<EventHome />} />*/}
              {/*<Route exact path="/event/detail" element={<EventDetail />} />*/}
              {/*<Route exact path="/event/comment" element={<EventComment />} />*/}
              {/*<Route exact path="/beautyNews/detail" element={<BeautyNewsDetail />} />*/}
              {/*<Route exact path="/magazine" element={<MagazineHome />} />*/}
              {/*<Route exact path="/magazine/detail" element={<MagazineDetail />} />*/}
              {/*<Route exact path="/mdPick/detail" element={<MdPickDetail />} />*/}

              {/* 뷰티북 자체 이벤트 */}
              {/*<Route exact path="/event/summer" element={<EventDetailSummer />} />*/}
              {/*<Route exact path="/event/appreview" element={<EventDetailAppReview />} />*/}
              {/*<Route exact path="/notice/coupon" element={<CouponDetail />} />*/}

              {/* 회원 가입 & 계정 찾기 & 뷰티북 공통 */}
              {/*<Route path="/login" element={<Login />} />*/}
              {/*<Route path="/kakaoLogin" element={<KaKaoLogin />} />*/}
              {/*<Route path="/naverLogin" element={<NaverLogin />} />*/}
              {/*<Route path="/appleLogin" element={<AppleLogin />} />*/}
              {/*<Route path="/appleJoinChk" element={<AppleJoinChk />} />*/}
              {/*<Route path="/join" element={<Join />} />*/}
              {/*<Route path="/socialJoin" element={<SocialJoin />} />*/}
              {/*<Route path="/appleJoin" element={<AppleJoin />} />*/}
              {/*<Route path="/findid" element={<FindId />} />*/}
              {/*<Route path="/findpw" element={<FindPw />} />*/}
              {/*<Route path="/idresult" element={<IdResult />} />*/}
              {/*<Route path="/pwresult" element={<PwResult />} />*/}
              {/*<Route path="/myinfo/infomobile" element={<InfoMobile />} />*/}
              {/*<Route path="/setting" element={<Setting />} />*/}
              {/*<Route path="/terms" element={<Terms />} />*/}
              {/*<Route path="/terms/detail" element={<TermsDetail />} />*/}
              {/*<Route path="/terms/privacy" element={<PrivacyUse />} />*/}
              {/*<Route path="/notice" element={<Notice />} />*/}
              {/*<Route path="/notice/detail" element={<NoticeDetail />} />*/}
              {/*<Route path="/qna" element={<Qna />} />*/}
              {/*<Route exact path="/review/comment" element={<Comment />} />*/}
              {/*<Route exact path="/review/write" element={<Write />} />*/}
              {/*<Route exact path="/review/modify" element={<Modify />} />*/}
              {/*<Route exact path="/review/detail" element={<ReviewDetail />} />*/}
              {/*<Route exace path="/search/result" element={<SearchResult />} />*/}

              {/* 뷰티북 어드민 */}
              {/*<Route path="/admin" element={<AdminHome />} />*/}
              {/*<Route path="/admin/calendar" element={<AdminCalendar />} />*/}
              {/*<Route path="/admin/mypage" element={<AdminMypage />} />*/}
              {/*<Route path="/admin/booking" element={<AdminBook />} />*/}
              {/*<Route path="/admin/bookingChk" element={<AdminBookChk />} />*/}
              {/*<Route path="/admin/review" element={<AdminReview />} />*/}
              {/*<Route path="/admin/reservation/change" element={<AdminReservationChange />} />*/}
              {/*<Route path="/admin/qna" element={<AdminQna />} />*/}
            </Route>

            {/* 랜딩 이벤트 */}
            {/*<Route exact path="/rps" element={<RPS />} />*/}
            {/*<Route exact path="/info" element={<InfoLanding />} />*/}
            {/*<Route exact path="/managerInfo" element={<AdminInfoLanding />} />*/}

            {/* office - 심리 테스트 - 직장 동료가 보는 내 모습 */}
            {/*<Route path="/office" element={<OHome />} />*/}
            {/*<Route path="/officeQuestion" element={<OTest />} />*/}
            {/*<Route path="/officeResult" element={<OResult />} />*/}

            {/* king - 심리 테스트 - 킹력테스트 */}
            {/*<Route path="/king" element={<KHome />} />*/}
            {/*<Route path="/kingQuestion" element={<KTest />} />*/}
            {/*<Route path="/kingResult" element={<KResult />} />*/}
            {/*<Route path="/kingResultAll" element={<KResultAll />} />*/}

            {/* AD */}
            {/*<Route path="/qr" element={<QR />} />*/}
          </Routes>
        </BrowserView>

        <MobileView>
          {/*{loading && <Loader />}*/}
          {/*{confirmModalOpen && <ConfirmModalMsg msg={modalMsg} function={onConfirmModal} />}*/}

          <Routes>
            {/* 뷰티북 유저 */}
            {/*<Route exact path="/mypage" element={<Mypage />} />*/}
            {/*<Route exact path="/myinfo" element={<Myinfo />} />*/}
            {/*<Route exact path="/mypage/coupon" element={<Coupon />} />*/}
            {/*<Route exact path="/mypage/mileage" element={<Mileage />} />*/}
            {/*<Route exact path="/review" element={<ReviewHome />} />*/}
            {/*<Route exact path="/mypage/writable" element={<WritableReview />} />*/}
            {/*<Route exact path="/mypage/myreview" element={<MyReviewChk />} />*/}
            {/*<Route exact path="/account" element={<Account />} />*/}
            {/*<Route path="/mypage/wish" element={<Wish />} />*/}
            {/*<Route path="/pwChange" element={<PwChange />} />*/}
            {/*<Route path="/phoneChange" element={<PhoneChange />} />*/}
            {/*<Route exact path="/mypage/booking" element={<Booking />} />*/}
            {/*<Route exact path="/booking/check" element={<BookingChk />} />*/}
            {/*<Route exact path="/magazine/comment" element={<MagazineComment />} />*/}
            {/*<Route exact path="/beautyNews/comment" element={<BeautyNewsComment />} />*/}
            {/*<Route exact path="/mdPick/comment" element={<MdPickComment />} />*/}
            {/*/!*<Route exact path="/reservation/date" element={<ReservationDate />} />*!/*/}
            {/*<Route exact path="/reservation/product" element={<ReservationProduct />} />*/}
            {/*<Route exact path="/reservation/payment" element={<ReservationPay />} />*/}
            {/*<Route exact path="/reservation/change" element={<ReservationChange />} />*/}
            {/*<Route exace path="/entered" element={<EnteredShop />} />*/}
            {/*<Route path="/push" element={<PushMsg />} />*/}

            {/* 뷰티북 유저 & 둘러보기 */}
            <Route path="/home" element={<Home />} />
            {/*<Route path="/" element={<Home />} />*/}
            {/*<Route exact path="/shop" element={<ShopList />} />*/}
            {/*<Route exact path="/project" element={<ProjectList />} />*/}
            {/*<Route exact path="/shopMap" element={<ShopMap />} />*/}
            {/*<Route exact path="/detail" element={<ShopDetail />} />*/}
            {/*<Route exact path="/CustomShop" element={<NoTopShopList />} />*/}
            {/*<Route exact path="/event" element={<EventHome />} />*/}
            {/*<Route exact path="/event/detail" element={<EventDetail />} />*/}
            {/*<Route exact path="/event/comment" element={<EventComment />} />*/}
            {/*<Route exact path="/beautyNews/detail" element={<BeautyNewsDetail />} />*/}
            {/*<Route exact path="/magazine" element={<MagazineHome />} />*/}
            {/*<Route exact path="/magazine/detail" element={<MagazineDetail />} />*/}
            {/*<Route exact path="/mdPick/detail" element={<MdPickDetail />} />*/}

            {/*/!* 뷰티북 자체 이벤트 *!/*/}
            {/*<Route exact path="/event/summer" element={<EventDetailSummer />} />*/}
            {/*<Route exact path="/event/appreview" element={<EventDetailAppReview />} />*/}
            {/*<Route exact path="/notice/coupon" element={<CouponDetail />} />*/}

            {/*/!* 회원 가입 & 계정 찾기 & 뷰티북 공통 *!/*/}
            {/*<Route path="/login" element={<Login />} />*/}
            {/*<Route path="/kakaoLogin" element={<KaKaoLogin />} />*/}
            {/*<Route path="/naverLogin" element={<NaverLogin />} />*/}
            {/*<Route path="/appleLogin" element={<AppleLogin />} />*/}
            {/*<Route path="/appleJoinChk" element={<AppleJoinChk />} />*/}
            {/*<Route path="/join" element={<Join />} />*/}
            {/*<Route path="/socialJoin" element={<SocialJoin />} />*/}
            {/*<Route path="/appleJoin" element={<AppleJoin />} />*/}
            {/*<Route path="/findid" element={<FindId />} />*/}
            {/*<Route path="/findpw" element={<FindPw />} />*/}
            {/*<Route path="/idresult" element={<IdResult />} />*/}
            {/*<Route path="/pwresult" element={<PwResult />} />*/}
            {/*<Route path="/myinfo/infomobile" element={<InfoMobile />} />*/}
            {/*<Route path="/setting" element={<Setting />} />*/}
            {/*<Route path="/terms" element={<Terms />} />*/}
            {/*<Route path="/terms/detail" element={<TermsDetail />} />*/}
            {/*<Route path="/terms/privacy" element={<PrivacyUse />} />*/}
            {/*<Route path="/notice" element={<Notice />} />*/}
            {/*<Route path="/notice/detail" element={<NoticeDetail />} />*/}
            {/*<Route path="/qna" element={<Qna />} />*/}
            {/*<Route exact path="/review/comment" element={<Comment />} />*/}
            {/*<Route exact path="/review/write" element={<Write />} />*/}
            {/*<Route exact path="/review/modify" element={<Modify />} />*/}
            {/*<Route exact path="/review/detail" element={<ReviewDetail />} />*/}
            {/*<Route exace path="/search/result" element={<SearchResult />} />*/}

            {/*/!* 뷰티북 어드민 *!/*/}
            {/*<Route path="/admin" element={<AdminHome />} />*/}
            {/*<Route path="/admin/calendar" element={<AdminCalendar />} />*/}
            {/*<Route path="/admin/mypage" element={<AdminMypage />} />*/}
            {/*<Route path="/admin/booking" element={<AdminBook />} />*/}
            {/*<Route path="/admin/bookingChk" element={<AdminBookChk />} />*/}
            {/*<Route path="/admin/review" element={<AdminReview />} />*/}
            {/*<Route path="/admin/reservation/change" element={<AdminReservationChange />} />*/}
            {/*<Route path="/admin/qna" element={<AdminQna />} />*/}

            {/*/!* 랜딩 이벤트 *!/*/}
            {/*<Route exact path="/rps" element={<RPS />} />*/}
            {/*<Route exact path="/info" element={<InfoLanding />} />*/}
            {/*<Route exact path="/managerInfo" element={<AdminInfoLanding />} />*/}

            {/*/!* office - 심리 테스트 - 직장 동료가 보는 내 모습 *!/*/}
            {/*<Route path="/office" element={<OHome />} />*/}
            {/*<Route path="/officeQuestion" element={<OTest />} />*/}
            {/*<Route path="/officeResult" element={<OResult />} />*/}

            {/*/!* king - 심리 테스트 - 킹력테스트 *!/*/}
            {/*<Route path="/king" element={<KHome />} />*/}
            {/*<Route path="/kingQuestion" element={<KTest />} />*/}
            {/*<Route path="/kingResult" element={<KResult />} />*/}
            {/*<Route path="/kingResultAll" element={<KResultAll />} />*/}

            {/*/!* AD *!/*/}
            {/*<Route path="/qr" element={<QR />} />*/}
          </Routes>
        </MobileView>
        <GlobalStyles />
      </ReactChannelIO>
    </>
  );
};

export default App;

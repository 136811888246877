import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import Loader from 'components/bb/components/layout/loading';

/* 공통 JS */
import * as Common from 'components/common/Common';

/* 모달 메세지 */
import ConfirmModalMsg from 'components/bb/components/modal/ConfirmModalMessage';

const PcLayOut = () => {
  const history = useNavigate();
  const cookies = new Cookies();
  const location = useLocation();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const [loading, setLoading] = useState(false);

  const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;

  ReactGA.initialize(gaTrackingId);

  // axios.interceptors.response.use(
  //   response => {
  //     setLoading(false);
  //
  //     return response;
  //   },
  //   async error => {
  //     setLoading(false);
  //
  //     return Promise.reject(error);
  //   }
  // );

  // useEffect(() => {
  //   axios.interceptors.request.use(
  //     function (config) {
  //       const notLoadingUrl = ['s3upload', 'userInfo', 'like'];
  //
  //       if (!loading && !notLoadingUrl.includes(config.url)) {
  //         setLoading(true);
  //       }
  //
  //       return config;
  //     },
  //     function (error) {
  //       return Promise.reject(error);
  //     }
  //   );
  // });

  useEffect(() => {
    history('/home');
    // ReactGA.set({ page: location.pathname });
    // ReactGA.pageview(location.pathname + location.search);
    //
    // window.scrollTo(0, 0);
    //
    // const url = [
    //   '/mypage',
    //   '/myinfo',
    //   '/mypage/mileage',
    //   '/mypage/coupon',
    //   '/mypage/writable',
    //   '/mypage/myreview',
    //   '/account',
    //   '/wish',
    //   '/pwChange',
    //   '/phoneChange',
    //   '/booking',
    //   '/booking/check',
    //   '/push',
    //   '/reservation/date',
    //   '/reservation/change',
    //   '/reservation/product',
    //   '/reservation/payment',
    // ];
    //
    // if (url.includes(location.pathname)) {
    //   if (location.pathname === '/reservation/date') {
    //     history('/home');
    //   }
    //
    //   if (Common.emptyValueValidation(cookies.get('accessToken'))) {
    //     setConfirmModalOpen(true);
    //     setModalMsg('로그인 후 이용가능합니다.\n로그인 하러 가기');
    //   }
    // }
    //
    // if (location.pathname.indexOf('/admin') > -1) {
    //   if (Common.emptyValueValidation(cookies.get('accessToken'))) {
    //     history('/home');
    //   }
    // }
  }, [location]);

  const confirmModalControl = val => {
    setConfirmModalOpen(false);

    if (val) {
      history('/login');
    }
  };

  return (
    <>
      <FrameCon>
        {/*<SideBox>*/}
        {/*  /!*<Rect />*!/*/}
        {/*</SideBox>*/}
        <SideContent>
          {loading && <Loader />}
          {confirmModalOpen && <ConfirmModalMsg msg={modalMsg} function={confirmModalControl} />}
          <Outlet />
        </SideContent>
        <BgView />
      </FrameCon>
    </>
  );
};

const FrameCon = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 30px;
`;

const SideBox = styled.aside`
  z-index: 3;
  position: relative;
  display: flex;
  width: 360px;
  height: 100vh;
`;

const SideContent = styled.div`
  z-index: 3;
  position: relative;
  width: 460px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 16px;
`;

const Rect = styled.div`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 360px;
  height: 520px;
  border-radius: 20px;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/admin/pc_qr.png') 50% 50% no-repeat;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  background-size: cover;
`;

const BgView = styled.div`
  z-index: 1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgb(245, 245, 245);
`;

export default PcLayOut;

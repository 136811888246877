import React, { useState } from 'react';

/* CSS */
import styles from 'components/bb/common/css/common.module.css';

const ModalMsg = props => {
  const [open, setOpen] = useState(true);

  const onClickModalBtn = () => {
    setOpen(prevValue => !prevValue);

    props.function(false);
  };

  return (
    <>
      {open && (
        <div className={styles.modalMsg}>
          <div className={styles.msgBox}>
            <div className={styles.msg}>
              <span dangerouslySetInnerHTML={{ __html: props.msg?.replaceAll('\n', '<br />') }}></span>
            </div>
            <button type="button" onClick={onClickModalBtn} className={styles.msgBtn}>
              확인
            </button>
          </div>
          <div className={styles.dimmed} />
        </div>
      )}
    </>
  );
};

export default ModalMsg;

import React, { useEffect, useState } from 'react';
import styles from 'components/king/common/css/common.module.css';
import MBTI from 'components/king/data/mbtiData';
import Collapse from '@kunukn/react-collapse';

function Esfp() {
  const matching = MBTI['ESFP'];
  const [isOpen, setIsopen] = useState(false);
  const [btnText, setBtnText] = useState('▼ 결과 펼쳐보기');

  useEffect(() => {
    if (isOpen === true) {
      setBtnText('▲ 결과 닫기');
    } else if (isOpen === false) {
      setBtnText('▼ 결과 펼쳐보기');
    }
  }, [isOpen]);

  return (
    <>
      {/* 결과창 컨텐츠 */}
      <div className={styles.resultBox}>
        <div className={styles.ResultImg}>
          <img src={matching.img} alt="직장 동료가 보는 내 모습"></img>
          <div className={styles.ResultTitle}>
            <strong>{matching.title}</strong>
            <span>{matching.modify}</span>
          </div>
        </div>
        <Collapse transition={`height 280ms cubic-bezier(.4, 0, .2, 1)`} isOpen={isOpen}>
          <div>
            <div className={styles.ResultDesc}>
              <ul className={styles.myDesc}>
                <li>
                  <strong>내가 킹 받을 때</strong>
                  <ul className={styles.description}>
                    {matching.descf.map(item => {
                      return (
                        <li className={styles.desc} key={item.des}>
                          {item.des}
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <strong>내가 상대를 킹받게 하는 포인트</strong>
                  <ul className={styles.description}>
                    {matching.descs.map(item => {
                      return (
                        <li className={styles.desc} key={item.des}>
                          {item.des}
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <strong>내가 화가 나면</strong>
                  <span className={`${styles.desc} ${styles.mt10}`}>{matching.desct}</span>
                </li>
              </ul>
            </div>
            <div className={styles.ResultType}>
              <div className={styles.TypeFight}>
                <span>
                  싸움이 일어 났을 때 <br />
                  당신은 주로 <strong>{matching.type}</strong>입니다.
                  <img src={matching.typeImg} alt="싸움이 주로 일어 났을 때 나의 유형" />
                </span>
              </div>
              <div className={styles.TypeFace}>
                <span>나의 디폴트 표정은?</span>
                <strong>{matching.face}</strong>
              </div>

              <div className={styles.TypeChemi}>
                <ul className={styles.chemi}>
                  <li>
                    <strong>
                      나를 킹받게
                      <br />
                      만드는 친구
                    </strong>
                    <ul className={styles.description}>
                      {matching.kFriend.map(item => {
                        return (
                          <li className={styles.desc} key={item.des}>
                            {item.des}
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li>
                    <strong>
                      NO KING
                      <br />
                      찰떡궁합♥
                    </strong>
                    <ul className={styles.description}>
                      {matching.bFriend.map(item => {
                        return (
                          <li className={styles.desc} key={item.des}>
                            {item.des}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Collapse>
        <button
          className={styles.collapseBtn}
          onClick={() => {
            setIsopen(state => !state);
          }}
        >
          {btnText}
        </button>
      </div>
    </>
  );
}

export default Esfp;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';
import moment from 'moment';

const ErrorPop = ({ setShowPop }) => {
  const closePop = () => {
    setShowPop(false);
  };

  return (
    <>
      <PopWrap id={'modalBox'} device={isBrowser}>
        <PopBox>
          <CloseBox>
            <button onClick={closePop}>
              <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/landing_close.svg" alt="닫기 버튼" />
            </button>
          </CloseBox>
          <div>
            <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/landing/inspection.png" alt="팝업 이미지" />
          </div>
        </PopBox>
        <Dimmed />
      </PopWrap>
    </>
  );
};

const PopWrap = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  width: ${({ device }) => (device === true && '460px') || (device === false && '100%')};
  height: 100vh;
`;

const PopBox = styled.div`
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CloseBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CookieBox = styled.div`
  & label {
    display: flex;
    align-items: center;
    gap: 0 10px;
    cursor: pointer;

    & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
    }
  }
`;

const Dimmed = styled.div`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

export default ErrorPop;

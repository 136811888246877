import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

/* CSS */
import styles from 'components/bb/common/css/common.module.css';
import inputForm from 'components/bb/common/css/member/inputForm.module.css';
import join from 'components/bb/common/css/member/inputForm.module.css';

/* 공통 JS */
import * as Common from 'components/common/Common';
import * as server from 'components/common/server';

/* 모달 메세지 */
import ModalMsg from 'components/bb/components/modal/ModalMessage';
import ConfirmModalMsg from 'components/bb/components/modal/ConfirmModalMessage';

const AppleJoinChk = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const [phone, setPhone] = useState('');
  const [isEnablePhone, setIsEnablePhone] = useState(false);
  const [authNumber, setAuthNumber] = useState('');
  const [authSeq, setAuthSeq] = useState('');
  const [isEnableAuthNumber, setIsEnableAuthNumber] = useState(false);
  const [phoneVd, setPhoneVd] = useState(true);

  const appleInfo = cookies.get('appleInfo');

  const onClickSetEnableAuthNumber = async () => {
    if (!Common.phoneValidation(phone) || phone.length !== 11) {
      setPhoneVd(false);

      return;
    }

    await server
      .sendPost(
        '/mendle/v1/common/sms/sendAuth',
        {
          phone_NUMBER: phone,
        },
        getAuthNumber
      )
      .then();
  };
  const getAuthNumber = res => {
    setIsEnablePhone(true);
    setAuthSeq(res['resultObject']);
  };
  const onClickCheckAuthentication = async () => {
    await server
      .sendPost(
        '/mendle/v1/common/sms/sendAuth/check',
        {
          auth_CODE: authNumber,
          seq: authSeq,
        },
        validationAuthNumber
      )
      .then();
  };
  const validationAuthNumber = res => {
    if (res['apiCode'] !== 'S001') {
      setModalMessage(res.message);

      if (res['apiCode'] === 'E040') {
        setIsEnablePhone(false);
      }
    } else {
      setIsEnableAuthNumber(true);
      setPhoneVd(true);
      setModalMessage('인증되었습니다.');
    }
  };
  const validationUserJoin = (joinInput, e) => {
    switch (joinInput) {
      case 'phone':
        e.target.value = Common.validateNumber(e.target.value);

        setPhone(e.target.value);

        break;
      case 'authNo':
        e.target.value = Common.validateNumber(e.target.value);

        setAuthNumber(e.target.value);

        break;
      default:
        break;
    }
  };
  const onSubmit = async () => {
    server
      .sendPost(
        '/mendle/v1/auth/login',
        {
          userId: appleInfo['userId'],
          snsAccessToken: '',
          phone: phone,
          joinType: 'APPLE',
        },
        loginSuccess
      )
      .then();
  };
  const loginSuccess = res => {
    if (res['apiCode'] === 'E043') {
      setConfirmModalOpen(true);
      setModalMsg(res.message);
    } else {
      setLoginModalOpen(true);
      setModalMsg('2024년 1월 29일 자로 신규 회원가입이 제한되었습니다.\n이용해주셔서 감사합니다.');
      /*const obj = {
        id: appleInfo['userId'],
        phone: phone,
      };

      cookies.remove('appleInfo');

      cookies.set('appleInfo', JSON.stringify(obj), { expires: Common.expireDate });

      navigate('/appleJoin');*/
    }
  };
  const setModalMessage = msg => {
    setModalOpen(true);
    setModalMsg(msg);
  };
  const modalControl = val => {
    setModalOpen(val);
  };
  const confirmModalControl = val => {
    setConfirmModalOpen(false);

    if (val) {
      socialIdIntegrated();
    } else {
      cookies.remove('appleInfo');

      navigate('/login');
    }
  };
  const socialIdIntegrated = () => {
    server
      .sendPut(
        '/mendle/v1/user/setUserInfo',
        {
          phone: phone,
          socialId: appleInfo['userId'],
        },
        integratedSuccess
      )
      .then();
  };
  const integratedSuccess = res => {
    if (res['apiCode'] === 'S001') {
      server
        .sendGet(
          '/mendle/v1/auth/appleUserInfo',
          {
            code: appleInfo['userId'],
          },
          getAppleUserInfo
        )
        .then();
    }
  };
  const getAppleUserInfo = res => {
    if (res['apiCode'] === 'S001') {
      const param = {
        userId: appleInfo['userId'],
        snsAccessToken: '',
        phone: phone,
        joinType: 'APPLE',
      };

      server.sendSocialLogin(param, isLogin).then();
    }
  };
  const isLogin = res => {
    if (res['apiCode'] === 'S001') {
      res['resultObject'].userLoginStatus = true;

      Common.loginCookie(res);

      navigate(res['resultObject']['userType'] === 'U' ? '/home' : '/admin');
    }
  };
  const onLoginModal = () => {
    setLoginModalOpen(false);

    navigate('/login');
  };
  const historyBtn = () => {
    navigate('/login');
  };

  return (
    <>
      {modalOpen && <ModalMsg msg={modalMsg} function={modalControl} />}
      {confirmModalOpen && <ConfirmModalMsg msg={modalMsg} function={confirmModalControl} />}
      {loginModalOpen && <ConfirmModalMsg msg={modalMsg} function={onLoginModal} />}
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.back} onClick={historyBtn}>
            <img src="https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/back_ic.svg" alt="뒤로가기" />
          </button>
          <h4>회원 여부 확인</h4>
        </div>

        <div className={styles.beautyWrap}>
          <div className={inputForm.joinForm}>
            <form>
              <div className={inputForm.inputInner}>
                <div className={inputForm.inputTit}>
                  <h4>
                    휴대폰 번호<small>*</small>
                  </h4>
                </div>
                <div className={`${inputForm.inputBox} ${inputForm.btnBox}`}>
                  <input
                    type="tel"
                    id="phoneNo"
                    name="phoneNo"
                    placeholder="휴대폰 번호를 입력해 주세요."
                    maxLength="11"
                    disabled={isEnablePhone}
                    className={phoneVd ? join.inputButton : `${join.inputButton} ${join.error}`}
                    onChange={e => {
                      validationUserJoin('phone', e);
                    }}
                  />
                  <button type="button" disabled={isEnablePhone} className={!isEnablePhone ? '' : join.activeBtn} onClick={onClickSetEnableAuthNumber}>
                    인증번호 받기
                  </button>
                </div>
                <div className={join.inputBox}>
                  <input
                    type="tel"
                    id="authNo"
                    name="authNo"
                    placeholder="인증번호를 입력해 주세요."
                    maxLength="6"
                    value={authNumber}
                    disabled={!isEnablePhone || isEnableAuthNumber}
                    className={phoneVd ? inputForm.inputButton : `${inputForm.inputButton} ${inputForm.error}`}
                    onChange={e => {
                      validationUserJoin('authNo', e);
                    }}
                  />
                  <button type="button" disabled={!isEnablePhone || isEnableAuthNumber} className={!isEnableAuthNumber ? '' : inputForm.activeBtn} onClick={onClickCheckAuthentication}>
                    인증하기
                  </button>
                </div>
              </div>
              <div className={inputForm.submit}>
                <button type="button" onClick={onSubmit} disabled={!isEnableAuthNumber} className={inputForm.submitBtn}>
                  확인
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppleJoinChk;
